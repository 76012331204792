
/*Discount*/
.block.discount {
  padding-top: 1rem;
  cursor: pointer;
  #block-discount-heading {
    @include fontSmall;
    font-weight: normal;
    &:after {
      color: $colorGrey;
      @include fontIcon;
      content: "#{$iconArrow }";
      transform: rotate(180deg);
      @include defaultTrans;
      float: right;
    }
  }
  &.active {
    #block-discount-heading {
      &:after {
        color: $colorGrey;
        @include fontIcon;
        content: "#{$iconArrow}";
        transform: rotate(0deg);
        @include defaultTrans;
        float: right;
      }
    }
  }
  #discount-coupon-form {
    .field {
      label {
        display: none;
      }
      .control {
        margin: 0.5rem 0 1rem;
        input {
          width: 100%;
        }
      }
    }
  }
}
.checkout-cart-index {
  .page-main {
    padding-top: 7.7rem;
  }
}
.cart-container {
  padding-top: 5rem;
  max-width: 144rem;
  margin: 0 auto;
  padding-bottom: 28rem;
  @include flexStart;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  div#gift-options-cart {
    display: none;
  }
  form.form-cart {
    width: 95rem;
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          font-size: 1.2rem;
          line-height: 1;
          font-weight: bold;
          text-transform: uppercase;
          &:first-child {
            text-align: left;
          }
        }
        th {
          padding-top: 0;
          padding: 0 2rem 1rem 0rem;
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
          text-align: right;
          padding-right: 7.5rem;
          &.subtotal {
            text-align: right;
            padding-right: 0;
          }
          &.item {
            text-align: left;
          }
          &.actions {
            padding: 0;
            width: 24px;
          }
          &.price {
            padding-right: 4.5rem;
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          .control.size {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            padding-right: 8.2rem;
          }
          td {
            padding: 2.5rem 0rem 2.5rem 0rem;
            text-align: right;
            &.col.item {
              a {
                display: table-cell;
                padding-right: 0rem;
              }

              .product-item-details {
                display: table-cell;
                padding-left: 2rem;
                vertical-align: middle;
                padding-top: 1.1rem;
                line-height: 2.6rem;
                width: 60.5rem;
                padding-bottom: 0;
                text-align: left;
                span.product-item-brand {
                  width: 100%;
                  display: block;
                  padding-bottom: 1.1rem;
                  text-transform: uppercase;
                  font-weight: bold;
                }
                strong {
                  line-height: 1;
                }
                dl.item-otions {
                  margin-top: 2rem;
                }
              }
            }

            &.col.price {
              display: table-cell;
              vertical-align: middle;
              text-align: right;
              padding-right: 4.5rem;
            }

            &.col.qty {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              .label {
                display: none;
              }

              input {
                width: 6rem;
              }
            }

            &.col.subtotal {
              display: table-cell;
              vertical-align: middle;
              text-align: right;
              padding-right: 0;
            }
            input {
              width: 4.8rem;
              height: 4.8rem;
              @include flexCenter;
              text-align: center;
              padding: 0;
              margin-bottom: 0;
            }
            &.actions {
              position: relative;
              a {
                padding-left: 0;
              }
            }
          }
        }
        span.cart-price {
          font-weight: bold;
          letter-spacing: 0;

        }
        .item-actions {
          td {
            padding: 0;
          }
        }
        .actions-toolbar {
          text-align: right;
          padding-bottom: 1rem;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          margin-top: -3.5rem;
          @media only screen and (max-width: 768px) {
            margin-top: 0;
            flex-direction: row;
            flex-wrap: nowrap;
          }
          .towishlist span {
            display: none;
          }
        }
        &.cart.item {
          position: relative;
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
        }
      }
    }
    .cart.main.actions {
      padding-top: 2rem;
      @include flexEnd;
      .action {
        margin-left: 2rem;
        box-shadow: 0px 0px 0px 1px $colorGrey;
        margin-right: 0;
        width: 34.2rem;
        text-align: center;
        color: $colorGrey;
        &:hover {
          box-shadow: 0px 0px 0px 2px $colorGrey;
        }
      }

    }
  }
  .block.shipping {
    display: none;
  }
  caption.table-caption {
    display: none;
  }
  .cart-summary {
    width: 100%;
    max-width: 32.2rem;
    .summary.title {
      text-transform: uppercase;
      display: block;
      line-height: 1;
      padding-top: 0;
      padding: 0 2rem 1rem 0rem;
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    button, .primary {
      width: 100%;
    }

    .cart-totals {
      .table-wrapper {
        .data.table.totals {
          width: 100%;
          .table-caption {
            text-align: right;
          }
          .totals-tax {
            padding-top: 0;
          }
          th, td {
            line-height: 1;
            padding: 0;
            padding-bottom: 1.6rem;
          }

          th.mark {
            text-align: left;

          }
          td.amount {
            text-align: right;
          }
        }
        tr.grand.totals {
          color: black;
        }
        .load.indicator {
          background-color: rgba($colorLighterGrey, 0.5);
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat
            50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }

          > span {
            display: none;
          }
        }

        .loading-mask {
          bottom: 0!important;
          left: 0!important;
          margin: auto;
          position: fixed!important;
          right: 0!important;
          top: 0!important;
          z-index: 100;
          background: rgba($colorLighterGrey, 0.5);

          .loader > {
            img {
              bottom: 0;
              left: 0;
              margin: auto;
              position: fixed;
              right: 0;
              top: 0;
              z-index: 100;
            }

            p {
              display: none;
            }
          }
        }

        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }
        [data-role="main-css-loader"] {
          display: none;
        }
      }

    }
    .block.discount {
      cursor: pointer;
      padding-bottom: 2rem;
      .field {
        margin-bottom: -1.5rem;
      }
      .fieldset.coupon {
        label {
          display: none;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    flex-direction: column-reverse;
    .cart.main.actions {
      padding-left: 0;
      padding-right: 0;
    }
    .cart-summary, form.form-cart {
      width: 100%;
      max-width: 100%;
      order: 2;
    }
    .cart-summary {
      margin-top: 3rem;
    }
  }
  @media only screen and (max-width: 1024px) {

  }
  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 7rem;
    .cart-summary .summary.title {
      padding-bottom: 2.4rem;
    }
    form.form-cart .cart.main.actions {
      justify-content: flex-start;
      .action {
        margin-left: 0;
        width: 100%;
        &.continue {
          margin-right: 1rem;
        }
        &.update {
          margin-left: 1rem;
        }
      }
    }
    .cart-summary .block.discount {
      padding-bottom: 2rem;
    }
    .cart.table-wrapper .col.size[data-th]:before, .cart.table-wrapper .col.price[data-th]:before, .cart.table-wrapper .col.qty[data-th]:before, .cart.table-wrapper .col.subtotal[data-th]:before {
      padding-bottom: 3px;
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
    }
    form.form-cart {
      table {
        margin: 0;
        td {
          padding: 1rem;
          text-align: center;
        }
        tbody tr {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
          font-size: 1.4rem;
          td.col.item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            padding-bottom: 0;
            text-align: center;
            width: 100%;
            padding-top: 0;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            text-align: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            flex-direction: row;
            .product-item-details {
              width: inherit;
              padding: 0;
              text-align: center;
              display: flex;
              align-items: baseline;
              justify-content: center;
              align-items: center;
            }
            a {
              width: 100%;
            }
            a span {
              width: 100% !important;
              height: 100% !important;
              img {
                width: 100%;
                height: 100%;
                max-width: 10rem;
              }
            }
          }
          td {
            text-align: center;
            &.col.size, &.col.qty {
              width: 50%;
              vertical-align: middle;
              text-align: center;
              justify-content: center;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            span.cart-price {
              font-weight: bold;
              letter-spacing: 0;
              height: 4.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .control.size {
              justify-content: center;
              padding: 0;
            }
          }
        }
        thead .col:not(.item) {
          display: none;
        }
      }
    }
    .cart-wrapper .col.size, .cart.table-wrapper .col.msrp, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal {
      width: 50%;
      padding-right: 0;
      text-align: center;
    }
    .block-giftregistry-shared-items .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo {
      position: static;
    }

  }
  @media only screen and (max-width: 480px) {
    form.form-cart .cart.main.actions {
      flex-wrap: wrap;
      .action.continue {
        margin-right: 0rem;
      }
      .action.update {
        margin-left: 0rem;
      }
    }
    form.form-cart table tbody tr td {
      padding: 1.5rem 0rem 1.5rem 0rem;
      font-size: 1.8rem;
    }
    form.form-cart table tbody tr td input {
      border: 0;
      font-weight: bold;
      height: 1.8rem;
    }
    form.form-cart table tbody tr td span.cart-price {
      height: 1.8rem
    }
  }
  @media only screen and (max-width: 320px) {

  }

}


/*
.product {
  .action-skip-wrapper {
    display: none;
  }
}
.catalog-product-view {
  .column.main{
    padding-top: 4.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .page-title-wrapper {
    padding-top: 0;
  }
  .product-info-main {
    width: 52%;
    margin-top: 0;
    padding: 4rem;
    .delivery_time {
      order: 3;
      margin: 3rem 0 1rem;
      display: flex;
      .type {
        margin-right: 0.5rem;
        &::after {
          content: ": ";
        }
      }
    }
    .product-info-price {
      order: 1;
      display: flex;
      flex-direction: column;
      .product-info-stock-sku {
        order: 3;
        display: flex;
        justify-content: space-between;
        .product.attribute.sku {
          display: flex;
          .type {
            margin-right: 0.5rem;
            &::after {
              content: ": ";
            }
          }
        }
      }
      .product-reviews-summary {
        order: 4;
        margin: 1rem 0 2rem;
      }
      .price-box.price-final_price {
        order: 1;
        @include fontLarger;
        font-weight: bold;
        color: black;
        .special-price {
          color: $colorPrimary;
          .price-label {
            display: none;
          }
        }
        .old-price {
          @include fontPriceProductSale;
          .price-label {
            display: none;
          }
        }
      }
      .price-details {
        order: 2;
        border-bottom: 0.1rem solid $colorLightGrey;
        margin: 0.5rem 0 1rem 0;
        padding-bottom: 1rem;
      }
    }
    .product-add-form {
      label.label {
        display: none;
      }
      .fieldset {
        display: flex;
        align-items: center;
        input {
          max-width: 6rem;
          margin-right: 1rem;
          padding: 0;
          text-align: center;
          padding-right: 0.5rem;
        }
      }
    }
     .product.attribute {
       margin-top: 2rem;
     }
    .product.attribute.overview {
      margin-bottom: 2rem;
    }
  }
  .product.info.detailed {
    margin-top: 4.5rem;
    width: 100%;
    .product.ticket-information ul {
      li {
        display: flex;
        span {
          width: 80%;
          padding: 2px;

          min-width: 120px;
          display: inline-flex;
          margin-bottom: 0.5rem;
          margin-right: 1rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          &:first-child {
            width: 20%;
            border-right: 1px solid rgba(26, 23, 27, 0.2);
            margin-right: 15px;
          }
        }
      }
    }

    .product.data.items {
      position: relative;
      z-index: 1;
      > .item {
        &.title {
          float: left;
          width: auto;
          margin: 0 0.5rem 0 0;
          > .switch {
            display: block;
            position: relative;
            z-index: 2;
            color: $colorLighterGrey;
            box-shadow: 0 0 4px 0 rgba(26, 23, 27, 0.2);
            border-bottom: none;
            min-height: 5.2rem;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: $colorPrimary;
              color: $colorWhite;
            }
            &:active {
              background-color: $colorPrimary;
            }
          }
          &.active > .switch {
            background: $colorPrimary;
            color: $colorWhite;
            text-decoration: none;
            box-shadow: none;
          }
        }
        &.content {
          margin-top: 2rem;
          float: right;
          margin-left: -100%;
          width: 100%;
          margin-top: 5.2rem;
          padding: 2rem;
          color: white;

          background-color: $colorSecondary;
          h3 {
            color: white;
          }
          ul {
            li {
              &:before {
                color: #fff;
              }
            }
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }
  .block.related {
    margin: 4rem 0;
    width: 100%;
    order: 4;
    .block-actions {
      display: none;
    }
    .title {
      @include fontLarge;
      text-align: center;
      margin-bottom: 2rem;
    }
    .products-related {
      ol.product-items {
        justify-content: space-between;
        li {
          width: 100%;
          list-style: none;
          span.product-image-container {
            width: 100% !important;
            img {
              width: 100% !important;
            }
          }
          .price-final_price {
            font-weight: bold;
            @include fontLarge;
          }
          .actions-secondary, .field.choice.related {
            display: none;
          }
        }
      }
    }
  }
  .product.media {
    width: 44%;
  }
}




!*Galerie*!
.gallery-placeholder._block-content-loading {
  background-image: url($imageDir + "loader.gif");
  bottom: 0;
  height: 6.4rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 6.4rem;
  z-index: 100;
}
.gallery-placeholder._block-content-loading .gallery-placeholder__image {
  opacity: 0;
}
.gallery-placeholder__image {
  width: 100%;
}*/

/*Product Detail*/
.catalog-product-view {
  .product.attribute.overview {
    padding-bottom: 4rem;
  }
  .product.description {
    ul {
      margin-left: 3rem;
      margin-bottom: 2.5rem;
      margin-top: 1.5rem;
      li {
        &:before {
          content: "\2022";
          color: #fff;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
  aside.modal-popup.modal-slide {
    font-size: 1.2rem;
    img {
      display: none;
    }
    &._show {
      .content {
        display: block !important;
      }
      img {
        margin: 0 auto;
        width: 25%;
        display: block;
      }
    }
    button.action-close {
      cursor: pointer;
      span {
        width: 100%;
      }
      &:before {
        color: $colorGrey;
        content: "#{$iconClose}";
        @include fontIcon;
        position: static;
        font-size: 3rem;
        top: 0;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
      }
    }
  }
  .columns .column.main{
    padding-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: $contentWidth;
    margin: 0 auto;
    .product-info-back {
      position: absolute;
      z-index: 0;
      margin-top: -4rem;
      display: flex;
      a {
        font-size: 1.4rem;
        font-weight: bold;
        padding-left: 15px;
      }
      &:before {
        @include fontIcon;
        @include icon;
        transform: rotate(-90deg);
        content: $iconArrow;
        font-size: 1.2rem;
        @include defaultTrans;
        position: absolute;
        left: 0rem;
        top: 0rem;
      }
    }
    .product.media {
      max-width: 60rem;
      width: 100%;
      margin-right: 2rem;
    }
    a.action.skip.gallery-next-area, .action-skip-wrapper {
      display: none;
    }
    .product-info-media {
      max-width: 62.5rem;
      position: relative;
      width: 100%;
      margin-bottom: 7rem;
      .fotorama__stage__frame.fotorama__active.fotorama_vertical_ratio.fotorama__loaded.fotorama__loaded--img {

      }
    }
    .fotorama__thumb-border {
      border-color: $colorPrimary;
    }
    .product.media {
      width: 44%;
      position: relative;

    }
    .product-info-main {
      width: calc(100% - 62.5rem);
      margin-top: 0;
      padding-top: 0.8rem;
      padding-left:4rem;
      line-height: 2.9rem;
      padding-bottom: 6rem;
      display: flex;
      flex-direction: column;
      position: relative;
      .product-size-chart {
        margin-bottom: 5rem;
        .action.link {
          padding: 0;
          border: 0;
          text-transform: none;
          text-decoration: underline;
          margin-bottom: 1rem;
          background-color: transparent;
          font-weight: bold;
          font-size: 1.6rem;
          letter-spacing: 0rem;
        }
      }
      .product.brand.product-item-brand {

      }

      .page-title-wrapper.product h1.page-title {
        text-transform: uppercase;
        padding-bottom: 4rem;
        line-height: 1;
        text-align: left;
        @media only screen and (max-width: 768px) {
          padding-bottom: 1.5rem;
        }
      }
      span.price-label {
        display: none;
      }
      .swatch-attribute-label {
        @include fontSmall;
        font-weight: bold;
        text-transform: uppercase;
        display: block;
        line-height: 1;
        padding-bottom: 1.3rem;
      }
      .product-info-details {
        @include flexBetween;
        padding-bottom: 2.8rem;
        .shipping-details {
          a {
            text-decoration: underline;
          }
        }
        & > div {
          padding-right: 4.5rem;
        }
        .product-info-category {
          ul {
            @include flex;
            li {
              a.action.tocategory.secondary {
                margin-right: 2rem;
                border-color: #C8C8C8;
                color: #000;
                &:hover {
                  box-shadow: 0 0 1px 1px #000;
                }
              }
              &:first-child {
                a {

                }
              }
            }
          }
        }
        .product-info-new {
          position: absolute;
          right: 19.5rem;
          top: -2rem;

          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }
        .product-info-special-price {
          position: absolute;
          right: 19.5rem;
          top: -2rem;
          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }
        .product-info-icon {
          a {
            @extend .emptyButton;
            color: transparent;
            border-radius: 0;
            span {
              display: none;
            }
          }
        }
        &:last-child {
          @include fontSmaller;
          padding-bottom: 0;
          justify-content: flex-start;
        }
      }

      .product.brand.product-item-brand {
        text-align: left;
        font-weight: normal;
        strong {
          font-weight: normal;
        }
      }
      .product.spice_type.product-item-spice_type {
        padding-bottom: 2.5rem;
        strong {
          @include fontLarge;
        }
      }
      .product.attribute.logo {
        padding-bottom: 1.5rem;
      }
      .product-add-form {
        padding-right: 4.5rem;
        order: 3;
        .swatch-opt {
          display: flex;
          flex-direction: column;

          .swatch-attribute.size {
            order: 1;
          }
          .swatch-attribute.color {
            order: 0;
          }
        }
      }
      .product-info-price {


        .price-final_price {
          font-size: 4.3rem;
          line-height: 4.3rem;
          font-weight: bold;
          //color: $colorPrimary;
          position: relative;

          .percentage {
            color: $colorPrimary;
            @include fontSmaller;
            margin-right: 1.5rem;
          }

          .old-price {
            color: $colorLightGrey;
            display: inline-flex;
            text-decoration: line-through;
            span {
              font-size: 2.4rem;
              color: $colorLightGrey;
              line-height: 4.5rem;
            }

            .price-label {
              display: none;
            }
          }
        }
        .price-box.price-final_price {
          @include fontLarger;
          text-align: left;
          justify-content: flex-start;
          .percentage {
            margin-right: 2rem;
          }
        }
        .price-details {
          margin-bottom: 1rem;
          @include fontSmall;
          a {
            color: #000;
            &:hover {
              color: $colorPrimary;
            }
          }
        }
      }
      .product.attribute.description {
        padding-bottom: 3rem;
        max-width: 54rem;
        h3 {
          color: white;
        }
      }
      .product-add-form {
        .swatch-attribute.color {
          padding-bottom: 5rem;
          @media only screen and (max-width: 768px) {
            padding-bottom: 4.5rem;
          }
        }
        .swatch-attribute-options.clearfix {
          @include flex;
          align-items: center;
          flex-wrap: wrap;
          .swatch-option.text {
            margin-bottom: 1.2rem;
            width: 4.8rem;
            @include defaultTrans;
            box-shadow: 0px 0px 0px 1px $colorGrey;
            height: 4.8rem;
            border-radius: 0;
            @include flexCenter;
            margin-right: 1.2rem;
            &.selected{
              box-shadow: 0px 0px 0px 2px $colorBlack;
            }
            &:hover {
              @include defaultTrans;
              box-shadow: 0px 0px 0px 2px $colorGrey;
            }
          }
        }
        .box-tocart {
          padding-bottom: 4.5rem;
          @media only screen and (max-width: 768px) {
            padding-bottom: 0;
          }
          .fieldset {
            width: 100%;
            @include flex;
            .field.spice_amount {
              width: calc(100% - 32rem);
            }
            .field.qty {
              width: 10rem;

              @include flexCenter;
              display: none;
              label {
                display: none;
              }
              .control {
                @include flex;
                align-items: center;
                .remove, .add{
                  cursor: pointer;
                  text-decoration: none;
                  text-align: center;
                  width: 2.2rem;
                  height: 2.2rem;
                  border: 0.1rem solid $colorBlack;
                  border-radius: 50%;
                  font-size: inherit;
                  line-height: 1;
                  font-weight: normal;
                  color: transparent;
                }
                .remove {

                  @include background;
                  background-size: 1rem;
                }
                .add {

                  @include background;
                  background-size: 1rem;
                }
                .input-text.qty {
                  width: 4.8rem;
                  text-align: center;
                  text-decoration: none;
                  font-size: 2rem;
                  line-height: 2.9rem;
                  border: none;
                }
              }
            }
            .actions {
              margin-top: 1rem;
              min-width: 19rem;
              min-height: 5rem;
              @include flexEnd;
            }
          }
        }
      }
    }
    .product.info.detailed {
      width: 100%;
      h3 {
        color: white;
      }
      .product.data.items {
        position: relative;
        .item {
          &.title {
            float: left;
            a {
              display: block;
              position: relative;
              z-index: 2;
              text-transform: uppercase;
              line-height: 1;
              border-right: 0.1rem solid $colorPrimary;
              border-top: 0.1rem solid $colorPrimary;
              box-sizing: border-box;
              cursor: pointer;
              background-color: transparent;
              text-align: center;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              padding-left: 4rem;
              padding-right: 4rem;
              @media only screen and (max-width: 768px) {
                font-size: 1.4rem;
                padding-left: 2.5rem;
                padding-right: 2.5rem;
                line-height: 1;
              }
              @media only screen and (max-width: 480px) {
                width: 100%;
                text-align: left;
              }
            }
            &.active {
              a {
                text-transform: uppercase;
                border-left: 0;
                background-color: $colorPrimary;
                color: white;
              }
              &+.content .inner-content {
                opacity: 1;
              }
            }
            &:first-child a {
              border-right: 0.1rem solid $colorPrimary;
              border-left: 0.1rem solid $colorPrimary;

            }
          }
          &.content {
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: 4.9rem;
            padding: 3rem;
            background-color: $colorSecondary;
            color: white;
            border: 0.1rem solid $colorSecondary;
            @media only screen and (max-width: 768px) {
              margin-top: 4.5rem;
            }
            a {
              color: white;
              border-bottom: 1px solid white;
            }
            &.active {
              display: block;
            }
            .inner-content {
              opacity: 0;
              max-width: 60rem;
              margin: 0 auto;
              &.columns {
                @include flexBetween;
                flex-direction: row;
              }
              .left{
                width: 100%;
                max-width: 23.6rem;
                font-weight: bold;
              }
              .right {
                width: 100%;
                max-width: 30.5rem;
                ul {
                  li {
                    @include flexBetween;
                  }
                }
              }
              @media only screen and (max-width: 600px) {
                flex-wrap: wrap;
                .left, .right {
                  max-width: inherit;
                  width: 100%;
                }
              }
              .inner:not(:last-child){
                padding-bottom: 2.5rem;
              }
            }
          }
        }
      }
    }
    .page-title-wrapper {
      padding-top: 0;
    }

    @media only screen and (max-width: 1024px) {
      .product.media, .product-info-main {
        width: 100%;
      }
      .product.media {
        margin-bottom: 0;
        margin: 0 auto;
      }
      .product-info-main {
        .page-title-wrapper.product .page-title {
          padding-left: 0;
          padding-right: 0;
        }
        .product-info-details {
          > div {
            padding-right: 1rem;
            padding-bottom: 0.4rem;
          }
        }
        .product-add-form  {
          padding-right: 0;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .product.media, .product-info-main {
        width: 100%;
        padding-bottom: 3rem;
        padding-left: 0rem;
      }
      .product.info.detailed .product.data.items .item.content {
        padding: 3rem;
      }
      .product-info-details {
        flex-wrap: wrap;
      }
    }
    @media only screen and (max-width: 560px) {
      .product-info-details {
        > div {
          padding-right: 0.5rem;
        }
      }
      .product-info-main {
        padding-bottom: 4.4rem;
        .page-title-wrapper.product .page-title {
          font-size: 3rem;
          line-height: 4rem;
        }
        .product-info-price {
          .price-box.price-final_price {
            font-size: 2.4rem;
            line-height: 3.4rem;
          }
        }
        .product-add-form {
          .box-tocart {
            padding-bottom: 3rem;

            .fieldset {
              flex-wrap: wrap;
              .field.spice_amount {
                width: 100%;
              }
              .field.qty {
                justify-content: flex-start;
              }
            }
          }
        }
        .product-info-details {
          font-size: 1rem !important;
          line-height: 1.5rem;
          flex-wrap: wrap;
          padding-bottom: 2rem;
          .product.attribute.sku {
            width: 100%;
            padding-bottom: 0.4rem;
          }
        }
        line-height: 2.4rem;
      }
      .product.info.detailed {

        li {
          &:before {
            color: white;
          }
        }
      }
    }
    @media only screen and (max-width: 360px) {
      .field.qty {
        width: 40%;
      }
      .actions {
        width: 60%;
      }
    }
  }
}

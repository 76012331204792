.page-header {
  border-bottom: 0.1rem solid $colorBorder;
  padding: 2.5rem 0 2rem 0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  width: 100%;
  position: fixed;
  background-color: $colorWhite;
  z-index: 3;
  top: 0;
  .header-content {
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
    @include flexBetween;
    .header-left {
      .logo {
        padding-left: 3rem;
        width: 20rem;
        display: block;
        img {
          width: 13.6rem;
        }
      }
    }
    .header-right {
      margin-right: 3rem;
      width: calc(100% - 20rem);
      @include flexEnd;
      flex-wrap: wrap;
      .header-right-bottom {
        order: 5;
        display: block;
        width: 100%;
        position: relative;
        .mobile {
          display: none;
          @media only screen and (max-width: 1024px) {
            text-align: right;
            width: 18px;
            margin-left: 98%;
            display: block;
            right: 0;
            position: absolute;
            top: -12px;
              .nav-sections {
                position: absolute;
                top: 9.5rem;
                background-color: white;
                width: 100%;
                display: none;
                left: 0;
                padding-left: 0rem;
                padding-right: 0rem;
                box-shadow: 0px 1px 1px 1px #F4F4F4;
                a {
                  font-size: 2rem;
                }
                &.active {
                  display: block;
                }
                &.active + .action {
                  &:before {
                    transform: rotate(45deg);
                    left: 5px;
                    top: 0.7rem;
                    background: $colorBlack;
                  }
                  span {
                    left: -50px;
                    opacity: 0;
                    background: $colorBlack;
                  }
                  &:after {
                    transform: rotate(-45deg);
                    left: 5px;
                    top: 0.7rem;
                    background: $colorBlack;
                  }
                }

              }
              .action.nav-toggle {
                width: 1.8rem;
                height: 1.4rem;
                position: relative;
                display: inline-block;
                &:before, &:after, span {
                  background: $colorBlack;
                  content: '';
                  display: block;
                  width: 1.8rem;
                  height: 0.3rem;
                  position: absolute;
                  left: 0;
                  @include defaultTrans;
                  transition-property: transform, top, bottom, left, opacity, background;
                }
                &:before {
                  top: 0;
                }
                span {
                  top: 0.7rem;
                  color: transparent;
                }
                &:after {
                  top: 1.4rem;
                }
              }
            }
          }

        .desktop {
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
        .section-items {
          @include flexEnd;
          .section-item-title {
            display: none;
          }
          .section-item-content {
            ul {
              @include flexEnd;
              li {
                margin-left: 3rem;
                ul.submenu {
                  display: none;
                }
              }
            }
          }
        }
      }
      .header-right-top {
        @include flexBetween;
        align-items: flex-start;
        height: 4.4rem;
        .section.header-right-top-menu {
          ul {
            list-style: none;
            padding: 0;
            @include flexEnd;
            li {
              margin-left: 3rem;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .fal {
          font-weight: 400;
        }
        .section {
          @include flexStart;
          padding-left: 3rem;
          line-height: 1;
          color: $colorBlack;
          border-left: 0.2rem solid #000;
         &:not(:last-child) {
           margin-right: 3rem;
         }
          &:first-child{
            border: 0;
          }
          &.header-right-top-language {
            .switcher {
              .options {
                position: relative;
                .action.toggle {
                  cursor: pointer;
                  display: inline-block;
                  text-decoration: none;
                  &:after {
                    font-size: 1.2rem;
                    line-height: 2rem;
                    color: $colorBlack;
                    @include fontIcon;
                    content: "#{$iconArrow}";
                    transform: rotate(180deg);
                    display: inline-block;
                  }
                }
                ul.dropdown {
                  background: $colorWhite;
                  z-index: 100;
                  display: none;
                  position: absolute;
                  top: 100%;
                  right: 0;
                  li {
                    &:hover {
                      cursor: pointer;
                    }
                  }
                  .options.active ul.dropdown {
                    display: block;
                  }
                  a {
                    width: 3.7rem;
                    color: $colorBlack;
                    display: block;
                    margin: 0 0 0 3rem;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
                &.active {
                  ul {
                    display: block;
                  }
                }
              }
              &-label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .nav-toggle {
    display: none;
  }
}

.mage-error {
  color: $colorPrimary;
}
.messages {
  margin-bottom: 3rem;
}
.message {
  max-width: $contentWidth;
  margin-top: 4rem;
  padding: 1rem;
  display: block;
  margin: 0 auto;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.8rem;
  &.error {
    background: $colorError;
    color: $colorWhite;
  }
  &.success {
    background: $colorPrimary;
    color: $colorWhite;
    box-shadow: 0px 0px 0px 1px $colorPrimary;
  }
  &.info {
    background: $colorPrimary;
    color: $colorWhite;
    box-shadow: 0px 0px 0px 1px $colorPrimary;
  }
  &.empty {
    background: $colorPrimary;
    color: $colorWhite;
    box-shadow: 0px 0px 0px 1px $colorPrimary;
  }
  &.warning {
    background: $colorPrimary;
    color: $colorWhite;
    box-shadow: 0px 0px 0px 1px $colorPrimary;
  }
  &.notice {
    background: $colorPrimary;
    color: $colorWhite;
    box-shadow: 0px 0px 0px 1px $colorPrimary;
  }
  &.success,
  &.notice,
  &.warning,
  &.info,
  &.empty,
  &.error {
    a {
      color: $colorWhite;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: $colorWhite;
        text-decoration: underline;
      }
    }
  }
}
.field-error, .mage-error {
  color: $colorError;
  font-size: 1.4rem;
}
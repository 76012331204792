
@media only screen and (max-width: 1280px) {
  .page-wrapper .page-main, .breadcrumbs {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media only screen and (max-width: 1024px) {
  .modal-popup {
    .modal {
      &-content {
        .block{
          &-new-customer{
            width: 30%;
          }
          &-customer-login{
            width: 70%;
          }
        }
      }
    }
  }
  .checkout-container .opc-wrapper .control._with-tooltip .field-tooltip .field-tooltip-content {
    right: 0;
    width: 100%;
    top: 40px;
    height: 40px;
  }
  .checkout-container .opc-wrapper .control._with-tooltip .field-tooltip .field-tooltip-content:after, .checkout-container .opc-wrapper .control._with-tooltip .field-tooltip .field-tooltip-content:before {
    display: none;
  }
  .login-container .block.block-new-customer {
    width: 100%;
  }
  .mm-wrapper_opened .page-wrapper {
    margin-top: 0;
  }
  .page-wrapper .page-sharing-wrapper {
    position: absolute;
    top: 0;
  }
  h1 {

    font-size: 3.6rem;
  }
  .page-wrapper .page-main {
    margin-top: 6.5rem;
  }
  .page-wrapper .page-sharing-wrapper {
    right: 3rem;
  }
  .account .page-wrapper .page-main .page-title-wrapper .page-title {
    padding-bottom: 0;
  }
  .columns .column.main .block-title, .columns .column.main .legend {
    font-size: 2rem;
  }
  .products .products.list .product.item {
    margin-left: 1%;
    margin-right: 1%;
  }
  .products .products.list .product.item .product.details .short_description {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .columns .column.main .block-title, .columns .column.main .legend, body {
    font-size: 1.8rem;
  }
  .modal-popup {
    .modal {
      &-content {
        .block{
          &-authentication{
            flex-wrap: wrap;
          }

          &-new-customer{
            order: 0;
            padding-left: 0;
            padding-right: 2rem;
            padding-bottom: 4rem;
          }
          &-customer-login{
            border-right: 0;
          }
          &-new-customer, &-customer-login{
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px){
  .page-header .header-content {
    flex-wrap: wrap;
    .header-right {
      width: 100%;
      margin-right: 0;
      text-align: center;
      justify-content: center;
      margin-top: 1.4rem;
      .section.header-right-bottom-menu.mobile {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .category-list{

    li{
      a{
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .modal-popup {
    .modal {
      &-content {
        .block{
          &-content{
            .captcha-reload{
              margin: 0;
              box-shadow: none;
              border: 1px solid #d1d1d1;
            }
            .actions-toolbar{
              flex-wrap: wrap;
              div{
                a{
                  width: 100%;
                  margin: 0;
                  margin-top: 2rem;
                }
                button{
                  margin: 0;
                  text-align: center;
                  span{
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                  }
                }
              }
            }

          }
        }
      }
    }
  }
  .block-search .block.block-content .form {
    position: absolute;
    bottom: 1rem;
  }
  .page-wrapper {
    margin-top: 17.8rem;
  }
  .billing-address-details {
    line-height: 150%;
  }
  .checkout-cart-index .page-main {
    padding-top: 0rem;
  }
  .breadcrumbs ul {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .page-wrapper .page-sharing-wrapper {
    right: 1.8rem;
    .shariff ul li {
      width: 3.2rem;
      height: 3.2rem;
    }
    .shariff ul li a span:before {
      font-size: 1.6rem;
    }
  }
  .page-wrapper .page-main {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
  .page-wrapper .page-title-wrapper {
    padding-top: 3rem;
  }
  .page-header .header-content .header-left .logo {
    padding-left: 1.8rem;
  }
  h1 {
    font-size: 2.4rem;
    padding-bottom: 0;
  }
  .form fieldset:after, .columns .column.main .block-title, .columns .column.main .legend, body, .page-main .action, #minicart-content-wrapper .action, #btn-cookie-allow {
    font-size: 1.6rem;
  }
  .captcha .control.captcha .captcha-image {
    flex-wrap: wrap;
  }
  .form fieldset .control {
    margin-bottom: 1rem;
  }
  .page-main .reload.captcha-reload {
    width: 100%;
    margin: 0 auto;
    margin-top: 1.5rem;
  }
  img.captcha-img {
    width: 100%;
    max-width: 25rem;
  }
  .page-main .actions-toolbar {
    flex-wrap: wrap;
  }
  .primary, .secondary{
    width: 100%;
    a {
      width: 100%;
      text-align: center;
    }
  }
  .page-main .action, #minicart-content-wrapper .action, #btn-cookie-allow {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .page-wrapper .page-main {
    margin-top: 0rem;
  }
  .category-list{
    flex-wrap: wrap;
    li{
      width: 100%;
      margin-bottom: 8px;
    }
  }
}


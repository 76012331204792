.block.widget.webcrossing-base-textimage-single {
  margin-bottom: 7.5rem;
  h2, h3 {
    max-width: 60%;
  }
  .actions-primary  {
    margin: 20px 0;
  }
  .block-content {
    display: flex;
    justify-content: space-between;
    > div {
      width: 100%;
    }
    .block.block-image{
      margin: 0 20px;
      max-height: 480px;
      max-width: 480px;
      order: 1;
      min-height: 35.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      img {
        max-width: 480px;
        width: 100%;
      }
    }
    .block.block-text {
      order: 2;
      min-height: 35.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(50% - 10px);
    }
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      .block.block-image {
        width: 100%;
        text-align: center;
        margin-bottom: 2.5rem;
        max-width: 100%;
      }
      .block.block-text {
        width: 100%;

      }
    }
  }
}
.minicart-wrapper {
  line-height: 1;
  &.active {
    overflow: visible;

    .block-minicart {
      display: block;
    }
  }
  /*Miniwarenkorb Symbol*/
  .action.showcart {
    cursor: pointer;
    position: relative;
    &:before {
      @include fontIcon;
      font-size: 2rem;
      color: $colorBlack;
      content: "#{$iconCart}";
    }
    &:hover {
      &:before {
        color: $colorPrimary;
      }
    }
    &:active {
      &:before {
        color: $colorLighterGrey;
      }
    }
    .counter.qty {
      background: $colorPrimary;
      color: $colorWhite;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      overflow: hidden;
      text-align: center;
      white-space: normal;
      margin-top: -0.5rem;
      right: -5px;
      top: 0px;
      span.counter-number {
        font-size: 1rem;
      }
      .counter-label {
        display: none;
      }
    }
    .counter.qty.empty {
      display: none;
    }
    span.text {
      display: none;
    }
    &.active {
      &:before {
        color: $colorLighterGrey;
        content: "#{$iconCart}";
        @include fontIcon;
      }

      &:hover {
        &:before {
          color: $colorGrey;
        }
      }

      &:active {
        &:before {
          color: $colorLighterGrey;
        }
      }
    }
  }

  /*Miniwarenkorb Content*/
  .block-minicart {
    display: none;
    background: $colorWhite;
    border: 1px solid $colorLighterGrey;
    margin-top: 0.5rem;
    z-index: 101;
    width: 39rem;
    position: absolute;
    right: 0;
    box-shadow: 0 0.3rem 0.3rem $colorLighterGrey;
    padding: 2.5rem 2rem;
    padding-bottom: 1.5rem;
    li {
      margin: 0;
      &:hover {
        cursor: pointer;
      }
    }
    #minicart-content-wrapper {
      .block-title {
        .qty {
          display: none;
        }
      }
      .block-content {
        @include fontSmallest;
        margin-top: 1.5rem;
        .action.close {
          background: none;
          border: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 4rem;
          > span {
            height: 0.1rem;
            overflow: hidden;
            position: absolute;
            width: 0.1rem;
          }

          &:before {
            @include fontIcon;
            font-size: 2rem;
            color: $colorGrey;
            content: "#{$iconClose}";
          }

          &:hover {
            &:before {
              color: $colorGrey;
            }
          }

          &:active {
            &:before {
              color: $colorGrey;
            }
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .items-total {
          float: left;
          margin: 0;
        }

        .subtotal {
          text-align: right;
          @include flexEnd;
          .price-wrapper {
            padding-left: 0.5rem;
          }
        }
        .actions {
          text-align: center;
          .action {
            max-width: 29rem;
            width: 100%;
            @include fontSmaller;
            min-height: 3rem;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            &.delete, &.edit {
              border: none;
              padding: 0;
              margin: 0;
            }
          }
        }

        // items
        .minicart-items-wrapper {
          border: 0.1rem solid $colorLighterGrey;
          margin: 1rem 0rem;
          border-left: 0;
          border-right: 0;
          padding-top: 1.5rem;
          ol {
            li{
              padding: 2rem 0;
              &:not(:first-child) {
                border-top: 0.1rem solid $colorLighterGrey;
              }
              &:first-child {
                padding-top: 0;
              }
              .product {
                position: relative;
                @include flexStart;
                img.product-image-photo {
                  display: block;
                }
                .toggle {
                  display: block;
                  text-decoration: none;
                  cursor: pointer;
                  position: relative;
                  &:after {
                    color: $colorGrey;
                    content: "#{$iconClose}";
                    @include fontIcon;
                    position: static;
                    right: 2rem;
                    top: 0;
                  }
                }
                &.active {
                  > .toggle {
                    &:after {
                      content: "#{$iconClose}";
                    }
                  }
                }
                &-item-details {
                  padding-left: 1rem;
                  @include flexWrap;

                  .product-item-name {
                    @include fontSmall;
                    padding-bottom: 5px;
                  }
                  .price-wrapper {
                    @include fontSmall;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    display: block;
                  }
                  .price-including-tax {
                    margin: 0.5rem 0 0;
                  }
                  .price-excluding-tax {
                    margin: 0.5rem 0 0;
                  }
                  .product.actions {
                    position: absolute;
                    bottom: -1.5rem;
                    right: 0;
                  }
                }
                &.options {
                  .tooltip.toggle {
                    display: inline-block;
                    text-decoration: none;

                    > span {
                      border: 0;
                      clip: rect(0, 0, 0, 0);
                      height: 0.1rem;
                      margin: -0.1rem;
                      overflow: hidden;
                      padding: 0;
                      position: absolute;
                      width: 0.1rem;
                    }

                    &:after {
                      color: $colorGrey;
                      content: "#{$iconClose}";
                      @include fontIcon;
                      margin: -0.3rem 0 0 0.7rem;
                    }

                    .details {
                      display: none;
                    }
                  }
                }
                &-item-pricing {
                  width: 100%;
                  .details-qty.qty {
                    @include flex;
                    input {
                      height: 3rem;
                      margin-left: 1rem;
                      width: 5rem;
                      padding: 0;
                      text-align: center;
                    }
                    button {
                      text-shadow: none;
                      border: 0;
                      color: $colorPrimary;
                      background-color: transparent;
                      font-size: 1.2rem;
                      font-weight: bold;
                      padding-left: 1rem;
                    }
                  }
                }
                &.actions {

                }
              }
            }
            .price-minicart {
              margin-bottom: 0.5rem;
            }
            .update-cart-item {
              font-size: 1.1rem;
              vertical-align: top;
            }
            .subtitle {
              display: none;
            }
          }
        }
      }
    }
  }
}
// width
$maxWidth: 192rem;
$contentWidth: 130rem;

// images
$imageDir: "../../images/";
// icons
$iconDir: "../../images/icons/";

// colors + shades
$colorWhite: #fff;
$colorBlack: #000;
$colorPrimary: #e2001a;
$colorSecondary: #707173;
$colorGrey: #707173;
$colorLightGrey: #1a171b;
$colorBorder: #d1d1d1;
$colorError: #e2001a;
$colorSuccess: #B0C82C;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorBusiness: #2b292c;
$colorButton: #e2001a;
$colorLighterGrey: lighten($colorLightGrey, 20%);

// icons: use fontawesome pro unicode - leave empty for no icon
$iconCart: "\f290";
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconleftarrow: "\f060";
$iconCheckCircle: "\f058";
@font-face {
  font-family: 'AltoProCon';
  font-weight: normal;
  font-display: auto;
  src: url('../../fonts/AltoProCon-Normal.woff2') format('woff2'),
  url('../../fonts/AltoProCon-Normal.woff') format('woff'),/* Pretty Modern Browsers */
  url('../../fonts/AltoProCon-Normal.ttf') format('truetype');/* Safari, Android, iOS */
}
@font-face {
  font-family: 'AltoProCon';
  font-display: auto;
  font-weight: bold;
  src: url('../../fonts/AltoProCon-Bold.woff2') format('woff2'),
  url('../../fonts/AltoProCon-Bold.woff') format('woff'),/* Pretty Modern Browsers */
  url('../../fonts/AltoProCon-Bold.ttf') format('truetype');/* Safari, Android, iOS */
}
@mixin fontIcon {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
@mixin fontDefault {
  font-family: "AltoProCon";
  font-size: 2rem;
  color: $colorSecondary;
  line-height: 150%;
  letter-spacing: 0.05rem;
  font-weight: normal;
}
@mixin fontHeadline {
  font-family: 'AltoProCon';
  font-weight: bold;
  color: $colorLightGrey;
  margin: 0;
  padding: 0 0 0.2rem 0;
}
@mixin font20 {
  font-size: 2rem;
  @include fontHeadline;
  line-height: 150%;
  letter-spacing: 0.01rem;
}
@mixin fontSmallest {
  font-size: 1.4rem;
}
@mixin fontSmaller {
  font-size: 1.6rem;
}
@mixin fontSmall {
  font-size: 1.8rem;
}
@mixin fontLarge {
  font-size: 2.4rem;
}
@mixin fontLarger {
  font-size: 4rem;
  line-height: 125%;
}
@mixin fontLargest {
  font-size: 6rem;
  line-height: 150%;
}


/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
  font-weight: bold;
}

@mixin fontPriceProductsSpecial {
  font-weight: bold;
  color: $colorSecondary;
}

@mixin fontPriceProductsSale {
  font-weight: bold;
  text-decoration: line-through;
}

@mixin fontPriceProductSale {
  font-weight: bold;
  text-decoration: line-through;
}


body {
  @include fontDefault;
}
h1, h2, h3, h4, h5, h6 {
  @include fontHeadline;
}
h1 {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  line-height: 100%;
  padding-bottom: 2.5rem;
  &:not(:first-child) {
    padding-top: 2.5rem;
  }
}
h2 {
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  line-height: 125%;
  padding-bottom: 2.5rem;
  &:not(:first-child) {
    padding-top: 2.5rem;
  }
}
h3 {
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.01rem;
  font-weight: bold;
  &:not(:first-child) {
    padding-top: 2.5rem;
  }
}
h4, h5 {
  @include font20;
}

a {
  color: $colorBlack;
  text-decoration: none;
  &:hover {
    color: $colorPrimary;
  }
}
b, strong {
  font-weight: bold;
}

ol, ul {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}

/*Sidebar*/
.account .sidebar-main, .page-products .sidebar-main {
  width: 24%;
}
.sidebar-main {
  .title {
    display: none;
  }
  .content {
    padding: 0;
    li {
      + li {
        margin: 1.5rem 0 0;
      }
      &.current {
        border-left: 0.3rem solid $colorPrimary;
          strong {
           color: $colorPrimary;

        }
      }

      a,
      strong {
        padding: 0.5rem 1.8rem 0.5rem 1.5rem;
        @include fontLarge;
      }
    }
  }
  .filter {
    .block-title.filter-title {
      display: none;
    }
    strong.block-subtitle.filter-subtitle {
      text-transform: uppercase;
      border-bottom: 2px solid;
      width: 100%;
      display: block;
      @include fontLarge;
      color: $colorPrimary;
      margin-bottom: 1.5rem;
    }
    .filter-options-title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

footer.page-footer {
  padding: 3rem 3rem 4rem 3rem;
  background-color: $colorBlack;
  background: linear-gradient(90deg, $colorLightGrey 70%,$colorBusiness 30%);
  @include fontSmaller;
  color: $colorWhite;
  @media only screen and (max-width: 1024px) {
    background: black;
  }
  a {
    @include fontSmaller;
    color: $colorWhite;
    &:hover {
      color: $colorPrimary;
    }
  }
  h3 {
    color: $colorWhite;
    font-size: 2rem;
    font-weight: bold;
    line-height: 163%;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    position: relative;
  }
  .footer-content {
    .footer-top {
      .footer-top-container {
        @include flexBetween;
        align-items: flex-start;
        .footer-top-logo {
          a {
            display: block;
            img {
              display: block;
            }
          }
        }
        .footer-top-totop {
          text-align: right;
          text-transform: uppercase;
          position: relative;
          margin-right: 4.5rem;
          cursor: pointer;
          div {
            position: absolute;
            top: 1rem;
            right: -4rem;
            display: block;
            width: 1.7rem;
            height: 1.7rem;
            border-top: 0.6rem solid $colorWhite;
            border-right: 0.6rem solid $colorWhite;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            margin: 0 0 -1rem 2rem;
          }
          &:hover {
            color: $colorPrimary;
            div {
              border-color: $colorPrimary;
            }
          }
        }
      }
    }

    .footer-main {
      .footer-main-container {
        max-width: 160rem;
        margin: 4rem auto;
        @include flexStart;
        .section {
          width: 20%;
          ul {
            font-size: 1.6rem;
            margin-bottom: 2rem;
            overflow: hidden;
            li {
              line-height: 3.2rem;
              letter-spacing: 0.1rem;
            }
          }
          &.footer-main-menu-contact {
            width: 40%;
          }

        }
        @media only screen and (max-width: 1024px) {
          flex-wrap: wrap;
          justify-content: flex-end;
          .section,.section.footer-main-menu-contact {
            width: calc(33.3333%);
          }
        }
        @media only screen and (max-width: 768px) {
          .section,.section.footer-main-menu-contact {
            width: 100%;
          }
        }
      }
    }

    .footer-bottom {
      text-align: left;
      max-width: 160rem;
      margin: 0 auto;
      .footer-bottom-container {
        .section {
          display: inline-block;
          &.footer-bottom-copyright {
            .copyright {
              display: inline-block;
            }
          }
          &.footer-bottom-menu {
            ul {
              @include flex;
              float: none;
              margin-left: 3rem;
              li {
                font-weight: bold;
                margin-right: 2rem;
              }
            }
          }
          &.footer-bottom-social {
            width: 60.5%;
            float: right;
            margin-top: -1rem;
            ul {
              @include flex;
              li {
                a {
                  @include flexCenter;
                  font-size: 2.3rem;
                  width: 3.2rem;
                  height: 3.2rem;
                }
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        .section.footer-bottom-social, .section {
          width: calc(33.3333%) !important;
        }
      }
      @media only screen and (max-width: 768px) {
        .section.footer-bottom-social, .section.footer-bottom-menu {
          width: 100% !important;
          margin-bottom: 1.5rem;
          ul {
            margin-left: 0 !important;
          }
        }
        .section.footer-bottom-social {
          width: 100% !important;
          text-align: center;
          ul {
            justify-content: center;
          }
        }
      }
    }
  }
}

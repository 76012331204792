
.page-wrapper {
  padding-top: 12.2rem;
  .page-main {
    max-width: $contentWidth;
    margin: 0 auto;
    margin-bottom: 4.5rem;

    position: relative;

  }
  .page-sharing-wrapper {
    position: absolute;
    top: -7rem;
    right: 0;

  }
  .page-title-wrapper {
    padding-top: 4.5rem;
  }
}
body{
  .ui-datepicker {
    border: 1px solid $colorBorder;
    background: $colorWhite;
    padding: 3rem;
    .ui-datepicker {
      &-buttonpane {
        border-top: 0;
        @include flex;
        justify-content: space-between;
        align-items: center;
        button {
          float: none;
          @include fontDefault;
          cursor: pointer;
          padding: 1rem 2.5rem;

          @include fontSmall;
          border: none;
          line-height: 1;
          text-transform: uppercase;
          &.ui-datepicker-current{
            color: $colorWhite;
            background-color: $colorPrimary;
          }
        }
      }
      &-header{
        background: $colorBusiness;
        @include flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem;
      }
      &-title{
        order: 1;
        margin: 0;
        .ui-datepicker {
          &-month, &-year {
            border-color: $colorWhite;
            color: $colorWhite;
            padding: .3rem .8rem;
            background: transparent;
            height: auto;

          }
        }
      }
      &-calendar {
        .ui-datepicker-today {
          background: $colorSecondary;
          .ui-state-hover{
            background: $colorSecondary;
          }
          a {
            color: $colorWhite;
          }
        }

        th, td {
          font-size: 1.5rem;
        }

        th {
          padding: .4rem .7rem;
        }

        td {
          padding: 0;

          .ui-state-hover{
            background: $colorBusiness;
            color: $colorWhite;
          }
          a {
            text-align: center;
          }
        }
        .ui-state-active{
          background: $colorPrimary;
          color: $colorWhite;
          &.ui-state-hover{
            background: $colorPrimary;
            color: $colorWhite;
          }
        }
      }
      &-prev, &-next{
        position: relative;
        top: 0;
      }
      &-prev{
        order:0;
        left: 0;
        span{
          border-color: transparent $colorWhite transparent transparent;
        }
      }
      &-next{
        order:2;
        right: 0;
        span{
          border-color: transparent transparent transparent $colorWhite;
        }
      }
    }
  }
}
.date{
  .fieldset{
    .control{
      display: flex;
      input{
        margin-bottom: 0;
      }
      button{
        font-size: 0;
        line-height: 0;
        background: $colorBusiness;
        border:0;
        padding: 0 1rem;
        color: $colorWhite;
        @include flex;
        align-items: center;
        cursor: pointer;
        &:before{
          content: "\f073";
          font-size: 2.2rem;
          @include fontIcon;
          font-weight: 300;
        }
      }
    }
  }
}
.breadcrumbs {
  padding-top: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  max-width: $contentWidth;
  margin: 0 auto;
  color: $colorSecondary;

 ul {
   margin-left: -1rem;
   margin-right: -1rem;
   @media only screen and (max-width: 1024px) {
     margin-left: 2rem;
     margin-right: 2rem;
   }
 }
  li {
    display: inline-block;

    a {
      margin-left: 0;
    }
    a, strong {
      display: inline-block;
      margin: 0 1rem;
    }
    strong {
      opacity: 1;
    }
  }
  li + li::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border-top: 4px solid #8d8d8f;
    border-right: 4px solid #8d8d8f;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 0;
  }
}
.category-image {
  padding-bottom: 2.5rem;
  img {
    max-width: $contentWidth;
    width: 100%;
  }
}
.block.widget.webcrossing-carousel .block-content-carousel.fullwidth {

  padding-top: 12.2rem;
}
.category-description {
  padding-bottom: 3rem;
  ul {
    margin-left: 3rem;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    li {
      padding-bottom: 0.5rem;
      &:before {
        content: "\2022";
        color: $colorPrimary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
      &::marker {
        content: "";
      }
    }
  }
}
.product.description {
  ul {
    margin-left: 3rem;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    li {
      padding-bottom: 0.5rem;
      &:before {
        content: "\2022";
        color: $colorPrimary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
      &::marker {
        content: "";
      }
    }
  }
}
.cart-empty {
  a {
    color: $colorPrimary;
  }
}
.account, .page-products {
  .columns {
    @include flexStart;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .column.main {
    width: 74%;
  }
}
.page-layout-1column {
  .column.main {
    width: 100%;
  }
}
.block.widget.webcrossing-carousel {
  .block-content-carousel {
    .overlay {
      text-align: center;
      transform: none;
      color: $colorWhite;
      left: 0;
      right: 0;
      position: absolute;
      margin: 0 auto;
      text-align: center;
      max-width: 86rem;
      @include fontLarge;
      font-weight: bold;
      line-height: 125%;
      letter-spacing: 0.1rem;
      padding: 0 2rem;
      color: $colorWhite;
      text-shadow: 0 0 4px $colorLightGrey;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h2 {
        text-shadow: 0 0 0.4rem $colorLightGrey;
        @include fontLarger;
        color: $colorWhite;
        padding-bottom: 2.5rem;
      }
    }
  }
  .for-desktop {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  .for-mobile {
    display: none;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  }
}
.webcrossing {
  &-base {
    &-product-slider {
      max-width: 1920px;
      margin-bottom: 7.5rem;
    }
    &-text-single{
      padding-top: 3rem;
      margin-bottom: 7.5rem;
    }
  }
}
.category-list {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  li {
    margin-right: 1.5rem;
    a {
      padding: 1rem 1.5rem;
      border: 1px solid black;
      @include defaultTrans;
      &:hover {
        @include defaultTrans;
        border: 1px solid $colorPrimary;
      }
    }
  }
}
/*Paginierung*/

.toolbar-products {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  .toolbar-amount {
    display: flex;
    align-items: center;
    span.toolbar-number {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .modes {
    display: none;
    .mode-grid {
      &:before {
        content: "#{$iconGrid}";
        @include fontIcon;
        margin-right: 0.25rem;
        margin-left: 0.5rem;
      }
    }
    .mode-list {
      &:before {
        content: "#{$iconList}";
        @include fontIcon;
        margin-right: 0.25rem;
        margin-left: 0.5rem;
      }
    }
  }
  .pages {
    display: none;
    .pages-label {
      display: none;
    }
    ul {
      display: flex;
      li.item {
        list-style-type: none;
        width: 52px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        position: relative;
        color: $colorPrimary;
        a, strong {
          color: $colorBlack;
          font-weight:bold;
          .label {
            display:none;
          }
        }
        &.current {
          color:$colorPrimary;
          a, strong {
            font-weight: bold;
            color: $colorPrimary;
          }
        }
        .next {
          position: absolute;
          display: block;
          top: 1.3rem;
          content: '';
          width: 1.9rem;
          height: 1.9rem;
          border: 0;
          border-top: 0.7rem solid $colorPrimary;
          border-right: 0.7rem solid $colorPrimary;
          right: 0;
          padding: 0;
          transform: rotate(45deg);
          min-height: 0;
          &:before {
            display: none;
          }
        }
        .previous {
          position: absolute;
          display: block;
          top: 1.3rem;
          content: '';
          width: 1.9rem;
          height: 1.9rem;
          border: 0;
          border-bottom: 0.7rem solid $colorPrimary;
          border-left: 0.7rem solid $colorPrimary;
          right: 0;
          padding: 0;
          transform: rotate(45deg);
          min-height: 0;
          &:before {
            display: none;
          }
        }
      }
    }
    .action {
      &.next {
        span {
          display:none;
        }
        &:before {
          content: "#{$iconPagination}";
          @include fontIcon;
          display: inline-block;
        }
      }
      &.previous {
        span {
          display:none;
        }
        &:before {
          content: "#{$iconPagination}";
          @include fontIcon;
          transform: rotate(180deg);
          display: inline-block;
        }
      }
    }
  }
  .field.limiter {
    display: flex;
    align-items: center;
    label {
      display:none;
    }
    select {
      margin: 0 1rem;
      margin-left: 0;
      width: auto;
    }
  }
  .toolbar-sorter {
    display: flex;
    align-items: center;
    select {
      margin: 0 1rem;
      width: auto;
    }
    .sorter-action {
      @include fontIcon;
      &.sort-asc {
        color:$colorBlack;
        padding: 0;
        &:before {
          content: "#{
                        $iconSortAsc
                    }
          ";
        }
        span {
          display: none;
        }
      }
      &.sort-desc {
        color:$colorBlack;
        padding: 0;
        &:before {
          content: "#{
                        $iconSortDesc
                    }
          ";
        }
        span {
          display: none;
        }
      }
    }
  }
  &:last-child {
    margin-top: 2rem;
    margin-bottom:0;
    justify-content: center;
    & > .toolbar-sorter, .field.limiter {
      display: none;
    }
    & > .pages {
      display: inline-block;
      padding:2rem;
    }
  }
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
    .toolbar-sorter {
      width: 100%;
      justify-content: space-between;
      .sorter-action {
        width: 10%;
      }
    }
  }
}
.toolbar-products {
  &:last-child {
    .toolbar-amount {
      display: none;
    }
  }
}
/*Sidebar Shadow*/
//.sidebar .block.category {
//  box-shadow: 0 0 0.4rem 0 rgba(26, 23, 27, 0.2);
//  margin-bottom: 2rem;
//  padding: 1rem;
//}
/*Slick Dots*/
.slick-dots {
  display: flex;
  bottom: -2.4rem;
  justify-content: center;
  li {
    background-color: #f2f2f2;
    height: 0.5rem;
    display: block;
    width: 100%;
    position: relative;
    width: 90px;
    max-width: 90px;
    margin: 0 10px;
    button {
      display: none;
    }
    &.slick-active {
      background-color: $colorPrimary;
    }
    &.slick-active:before {
      width: 100%;
      -webkit-transition: width 5000ms linear;
      -o-transition: width 5000ms linear;
      -webkit-transition: width 5000ms linear;
      transition: width 5000ms linear;
    }
  }
}
/*Shariff*/
.shariff {
  ul {
    @include flexEnd;
    li {
      width: 4.8rem;
      height: 4.6rem;
      padding: 0;
      margin: 0;
      @include flexCenter;
      background-color: $colorSecondary;
      border-right: 0.2rem solid $colorWhite;
      a {
        width: 100%;
        height: 100%;
        @include flexCenter;
        span {
          &:before {
            color: $colorWhite;
            font-size: 2.4rem;
          }
        }
      }
      &:hover {
        background-color: #f5f5f5;
        outline: 0.1rem solid $colorSecondary;
        a {
          span {
            &:before {
              color: $colorPrimary;
            }
          }
        }
      }
    }
  }
}
/*Limiter*/
.pager {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include flexBetween;
  .limiter {
    @include flex;
    label {
      display:none;
      font-size: 1.4rem;
    }
    .limiter-text {
      font-size: 1.4rem;
    }
    select {
      margin: 0 1rem;
      width: auto;
    }
  }
}
/*Table*/
table {
  width: 100%;
  border-collapse: collapse;
  caption.table-caption {
    display: none;
    @include font20;
    padding: 1rem 1.1rem 1rem 0rem;
    margin-bottom: 2rem;
    text-align: left;
  }
  thead {
    th {
      @include fontSmall;
      padding: 2rem 1.1rem 1rem;
      border-bottom: 0.1rem solid $colorLightGrey;
      &:first-child {
        padding-left: 0;
      }
      &.item {
        text-align: left;
      }
      &.col.qty {
        text-align: right;
      }
      &.col.subtotal {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      padding: 2rem 1.1rem 1rem;
      &:first-child {
        padding-left: 0;
      }
      &.col.item {
        a {
          display: table-cell;
          max-width: 80%;
          padding-right: 4rem;
          position: static;
          vertical-align: top;
          width: 1%;
        }
        .product-item-details {
          display: table-cell;
          padding-bottom: 3.5rem;
          vertical-align: top;
          white-space: normal;
          width: 99%;
          strong.product-item-name {
            @include fontLarge;
          }
          dl.item-otions {
            margin-top: 2rem;
          }
        }
      }
      &.col.price {
        display: table-cell;
        vertical-align: top;
        @include fontLarge;
      }
      &.col.qty {
        display: table-cell;
        vertical-align: top;
        text-align: right;
        @include fontLarge;
        .label {
          display: none;
        }
        input {
          width: 6rem;
          height: 3rem;
          padding: 0;
          text-align: center;
        }
      }
      &.col.subtotal {
        @include fontLarge;
        display: table-cell;
        vertical-align: top;
        text-align: right;
      }
    }
  }
}

/*Shipping*/
.block.shipping {
  display: none;
  border-bottom: 0.1rem solid $colorLightGrey;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  #block-shipping-heading {
    font-size: 1.3rem;
    &:after {
      color: $colorPrimary;
      @include fontIcon;
      content: "#{$iconArrow}";
      transform: rotate(180deg);
      float: right;
    }
  }
  &.active {
    #block-shipping-heading {
      &:after {
        color: $colorPrimary;
        @include fontIcon;
        content: "#{$iconArrow}";
        transform: rotate(0deg);
        float: right;
      }
    }
  }
}






.block.filter {
  margin: 0 auto;
  max-width: $contentWidth;
  @include flex;
  position: relative;
  margin-bottom: 5rem;
  .block-title.filter-title {
    font-size: 1.4rem;
    line-height: 4.4rem;
    letter-spacing: 0.12rem;
    text-decoration: underline;
    padding-right: 5.5rem;
  }
  .filter-current {
    position: absolute;
    top: 4.5rem;
    left: 0;
    ol {
      @include flexWrap;
    }
    li.item {
      margin-right: 1.5rem;
      border: 1px solid #000000;
      border-radius: 40px;
      padding-left: 2rem;
      font-size: 1.4rem;
      letter-spacing: 1.12px;
      padding-right: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      line-height: 1;
      .action.remove {
        border: none;
        margin-right: 0;
        padding: 0;
        margin-left: 0.5rem;
        &:before {
          color: $colorGrey;
          content: "#{$iconClose}";
          @include fontIcon;
          position: static;
          top: 0;
          width: 2rem;
          height: 2rem;

        }
        span {
          display: none;
        }
      }
    }
    span.filter-label {
      display: none;
    }
  }
  dl {
    @include flex;
    dt.filter-options-title {
      padding-right: 5rem;
      position: relative;
      font-size: 1.4rem;
      line-height: 4.4rem;
      letter-spacing: 2.1px;
      color: black;
      text-transform: uppercase;
      cursor: pointer;
      span {
        position: relative;
        &:after {
          @include fontIcon;
          @include icon;
          transform: rotate(180deg);
          content: $iconArrow;
          font-size: 1.2rem;
          @include defaultTrans;
          position: absolute;
          top: 0.2rem;
          line-height: 1;
          right: -1.5rem;
          @media only screen and (max-width: 768px){
            display: none;
          }
        }
      }
      &.active span{
        &:after {
          @include defaultTrans;
          transform: rotate(0deg);
        }
      }
    }
    dd.filter-options-content {
      cursor: pointer;
      font-size: 1.4rem;
      letter-spacing: 1.12px;
      position: relative;
      @media only screen and (max-width: 768px){
       width: 100%;
      }
      &:first-child {
        margin-left: 3rem;
      }
      &.active {
        ol, .swatch-attribute.swatch-layered.size {
          @include defaultTrans;
          max-height: 30rem;
          overflow-y: scroll;
          padding-top: 1.3rem;
        }
      }
      .swatch-option.text {
        font-weight: bold;
      }
      .swatch-option.text.disabled {
        opacity: 0.5;
      }
      ol {
        z-index: 1;
        min-width: 14.4rem;
        position: absolute;
        margin-left: -16.9rem;
        background-color: white;
        margin-top: 4rem;
        padding-right: 2.4rem;
        padding-left: 2.4rem;
        max-height: 0;
        overflow: hidden;
        @include defaultTrans;
      }
      .swatch-attribute.swatch-layered.size {
        z-index: 1;
        max-height: 0;
        overflow: hidden;
        @include defaultTrans;
        position: absolute;
        min-width: 14.4rem;
        background-color: white;
        margin-left: -16.9rem;
        margin-top: 4rem;
        padding-right: 2.4rem;
        padding-left: 2.4rem;
      }
      li {
        padding-bottom: 1.4rem;
        line-height: 1.4rem;

      }
    }
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    .block-title.filter-title {
      width: 100%;
    }
    .block-content.filter-content {
      width: 100%;

    }
    dl {
      flex-wrap: wrap;
      dt.filter-options-title {
        width: 100%;
        &:before {
          @include fontIcon;
          @include icon;
          transform: rotate(180deg);
          content: $iconArrow;
          font-size: 1.2rem;
          position: absolute;
          right: 0rem;
          top: 0rem;
        }
      }
      dd.filter-options-content {
        &:before {
          display: none;
        }
        ol.items, .swatch-attribute.swatch-layered.size {
          position: inherit;
          margin: inherit;
          width: 100%;
        }
      }
    }
  }
}
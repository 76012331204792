.block.widget.webcrossing-base-category-teaser {
  ol.categories.list.items.category-items {
    @include flexBetween;
    li {
      position: relative;
      margin-bottom: 20px;
      width: calc((100% - 40px) / 3);
      img {
        width: 100%;
        display: block;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    ol.categories.list.items.category-items {
      flex-wrap: wrap;
      li {
        min-height: inherit;
        width: calc((100% - 20px) / 2);
        img {
          height: inherit;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    ol.categories.list.items.category-items {
      li {
        width: 100%;
      }
    }
  }
}



.block-search {
  @include flex;
  flex-direction: row-reverse;
  height: 2.4rem;
  .block {
    &.block-title {
      strong {
        display: none;
      }
      &:after {
          @include fontIcon;
        cursor: pointer;
          content: "#{$iconSearch}";
          color: $colorBlack;
        }
    }

    &.block-content {
      cursor: pointer;
      @include flex;
      flex-direction: row;
      .label {
        display: none;
      }
      .form {
        display: none;
        padding-right: 2.5rem;
        width: 100%;
        z-index: 1000;
        input {
          border: none;
          height: inherit;
          box-shadow: none;
          @include fontHeadline;

        }
      }
      .actions {
        display: none;
      }
    }
  }
}

/*Account*/
.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-createpassword  {
  .column.main {
    max-width: $contentWidth;
    margin: 0 auto;
    padding-bottom: 6rem;

  }
  .block-title,  {
    font-size: 2.2rem;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 1.8rem 0rem;
  }
  .page-title {
    padding-top: 5rem;
  }
}
.page-layout-2columns-left {
  .page-main {
    padding-top: 0rem;
  }
  .columns {
    max-width: $contentWidth;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 12rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
.account {
  .columns {
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
}
.customer-account-forgotpassword {
  .actions-toolbar {
    .secondary {
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
.customer-account-login {
  h1.page-title {
    text-align: left;
    max-width: $contentWidth;
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 480px) {
      padding-bottom: 0;
    }
  }
  .block-customer-login {
    .field.note {
      font-size: 2.2rem;
      line-height: 2.8rem;
      letter-spacing: 0.04rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
  .block.block-new-customer {
    .block-content {
      font-size: 2.2rem;
      line-height: 2.8rem;
      letter-spacing: 0.04rem;
      margin-right: 0;
      max-width: 100%;
      @media only screen and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      .actions-toolbar {
        .action.primary {
          width: 23.3rem;
          display: block;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
        }
      }
      ul {
        padding-top: 2rem;
        color: $colorGrey;
        li{

          position: relative;
          padding-left: 3.7rem;
          font-weight: bold;
          margin-bottom: 1.3rem;
          &:before {
            @include fontIcon;
            @include icon;
            color: #707070;
            font-size: 1.7rem;
            @include defaultTrans;
            position: absolute;
            left: 0rem;
            top: 0rem;
            height: 2.5rem;
            width: 2.5rem;
            font-size: 2.5rem;

          }
        }
      }
    }
  }
}
.account {
  .page-wrapper .page-main .page-title-wrapper .page-title {
    padding-bottom: 2.5rem;
    @media only screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }
  tbody .action.view {
    padding: 0;
    border: none;
    box-shadow: none;
    text-transform: none;
    margin-right: 0;
    &:hover {
      box-shadow: none;
      border-bottom: 1px solid black;
    }
  }
  .actions-toolbar {
    text-align: left !important;
    margin-top: 1rem;
  }
  .columns {
    .sidebar-main {
      a, strong {
        line-height: 2.6rem;
        display: block;
        font-size: 2rem;
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-top: 0rem;
        padding-bottom: 2.5rem;
        .current, a {
          padding-bottom: 0rem;
        }
      }
    }
    p.toolbar-amount {
      display: none;
    }
    .order-products-toolbar.toolbar {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: right;
      margin: 0;
      select {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
        text-align: right;
      }
    }
    .column.main {
      .table-wrapper {
        .table-caption {
          display: none;
        }
        table {
          th {
            text-align: left;
          }
        }
      }
      span.order-status, .order-date, .actions-toolbar.order-actions-toolbar a {
        @include fontSmall;
      }
      .table-wrapper.order-items {
        margin-bottom: 1.5rem;
        tr {
          display: flex;
          justify-content: space-between;
          td, th {
            display: block;
            &.name {
              width: 40%;
            }
            &.sku {
              width: 10%;
            }
            &.price {
              width: 15%;
              padding-left: 4rem;
            }
            &.qty {
              width: 30%;
              ul.items-qty {
                @include flex;
                .item {
                  padding-right: 1rem;
                }
              }
            }
            &.subtotal {
              text-align: right;
              width: 15%;
            }
          }
        }
        tfoot {
          margin-top: 2rem;
          display: block;
        }
      }
      .order-details-items.ordered {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

      }
      ul.items.order-links {
        display: none;
      }
      .limiter {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @include flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        select#limiter {
          width: 7.5rem;
          margin-left: 0.5rem;
          margin-right: 1rem;
        }
      }
      .box {
        position: relative;

        .box-content {
          padding-bottom: 1.5rem;
        }

        .box-actions {
          position: absolute;
          right: 0;
          top: 0;
          @include flexStart;
          justify-content: space-between;
        }
      }
      .block-title, .legend {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
        @include fontLarge;
        font-weight: bold;
        border-bottom: 0.1rem solid $colorBlack;
        padding-bottom: 1rem;
      }

      form {
        &.form-address-edit {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          fieldset {
            width: 49%;
          }
          @media only screen and (max-width: 768px) {
            justify-content: center;
            fieldset {
              width: 100%;
            }
          }
        }
      }
      .block.block-dashboard-addresses .block-title {
        .action {
          display: none;
        }
      }
      .block-addresses-default {
        padding-bottom: 2.5rem;
      }
      .block-addresses-list {
        .block-title {
          margin-bottom: 0;
        }
        p.empty {
          margin-top: 1rem;
          margin-bottom: 1rem;
          @include fontSmaller;
        }
      }

      .block-dashboard-orders {
        .block-content {
          > * {
            flex-basis: 100%;
          }
        }

        table {
          width: 100%;
          .table-caption {
            text-align: left;
          }
          th {
            text-align: left;
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }
  .wishlist {
    .limiter {
      margin: 0;
    }
    .product-item-info {
      @include flex;
      flex-wrap: wrap;
      > div, strong {
        width: 100%;
      }
      .price-box.price-configured_price {
        font-weight: bold;
      }
      .price-details {
        @include fontSmallest;
        padding-top: 1rem;
      }
      .product-item-inner {
        padding-top: 1.5rem;
        position: relative;
        .field.comment-box label {
          display: none;
        }
        .product-item-comment {
          width: 100%;
          min-height: 150px;
          padding: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .box-tocart {
          .field.qty {
            position: absolute;
            top: -3.6rem;
            right: 0;
            display: flex;
            align-items: center;
          }
          .control {
            margin: 0;
            margin-left: 1rem;
            input {
              width: 5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .page-wrapper .columns {
      justify-content: flex-end;
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: flex-start;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .page-wrapper .columns {
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: center;
      }
    }
  }
}
.customer-account-create {
  .actions-toolbar {
    display: flex !important;
    width: 100%;
    .secondary {
      box-shadow: none;
    }
    a {
      @media only screen and (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
  form#form-validate {
    @include flexWrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    .fieldset {
      width: 100%;
      legend {
        font-size: 2.2rem;
        font-weight: bold;
        color: $colorGrey;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
    }
  }
}

.additional-addresses {
  table {
    position: relative;

    th.col.state, td.col.state, th.col.phone, td.col.phone {
      display: none;
    }
    td.col.actions {
      padding-right: 0;
    }
  }
}

/*Login*/
.login-container {
  @include flexBetween;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  .block {
    width: 48%;
    border: none;
    display: block;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    .block-content {
      color: black;
      .actions-toolbar {
        margin-top: 3.5rem;
        .primary {
          width: 23.3rem;
          display: block;
          margin-right: 1.5rem;
        }
        .secondary {
          .action {
            text-align: center;
          }
        }
        a.action.remind {
          margin-right: 0;
        }
      }
    }
    &.block-new-customer {
      padding: 0rem 3.4rem;
      box-shadow: 0 0 4px 0 rgba(26, 23, 27, 0.2);
      width: 49%;
      padding-bottom: 3.4rem;

    }
  }
}
/*Passwort*/
.password.required {
  .control {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    #password {
      order: 1;
    }

    #password-error {
      color: $colorError;
      margin: 0.5rem 0;
      order: 3;
    }

    #password-strength-meter-container {
      background-color: $colorSecondary;
      padding: 1rem 0 1rem 1rem;
      order: 2;
      color: $colorWhite;
      margin-bottom: 1rem;
      #password-strength-meter {
        line-height: 1;
      }
      &.password-very-strong {
        background-color: $colorSuccess;
      }
      &.password-strong {
        background-color: $colorSuccess;
      }
      &.password-medium {
        background-color: $colorInfo;
      }
      &.password-weak {
        background-color: $colorError;
      }
      &.password-error {
        background-color: $colorError;
      }
    }
  }
}
.swatch-opt {
  width: 100%;
  margin-bottom: 1.5rem;
  .swatch-option.text {
    min-width: 60px;
    padding: 5px 5px;
    margin-right: 5px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .swatch-attribute.tour_type {
    display: flex;
    align-items: center;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  }
  .swatch-attribute-label {
    margin-right: 1.5rem;
  }
  .swatch-attribute-selected-option {
    display: none;
  }
  .swatch-option {
    cursor: pointer;
    margin-right: 1.2rem;
    float: left;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(26, 23, 27, 0.2);
    border: none;
    font-size: 1.4rem;
    line-height: 200%;
    letter-spacing: 0.1rem;
    height: 5.4rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    color: #e2001a;
    width: 5.4rem;
    &.disabled {
      opacity: 0.1;
      @include defaultTrans;
    }
    &.selected {
      box-shadow: 0 0 4px 0 rgba(26, 23, 27, 1);
    }
  }
  input.swatch-input.super-attribute-select {
    display: none;
  }
  .swatch-attribute {
    &.size .text{
      min-width: inherit !important;
      @include fontDefault;
      font-size: 1.8rem;
    }
  }
}

@mixin rotateCCW10 {
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
@mixin rotateCCW15 {
  -moz-transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
@mixin defaultTrans {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
@mixin shadow {
  box-shadow: 0 0 4px 0 $colorLighterGrey;
}
@mixin defaultFastTrans {
  transition: all 250ms ease;
}
@mixin transition {
  transition: all 500ms ease;
}
@mixin flex {
  display: flex;
  align-items: center;
}
@mixin flexWrap {
  @include flex;
  flex-wrap: wrap;
}
@mixin flexCenter {
  @include flex;
  justify-content: center;
}
@mixin flexColumn {
  @include flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
@mixin flexVertCenter {
  @include flex;
  align-items: center;
}
@mixin flexBetween {
  @include flex;
  justify-content: space-between;
}
@mixin flexEnd {
  @include flex;
  justify-content: flex-end;
}
@mixin flexStart {
  display: flex;
  align-items: flex-start;
}
@mixin background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@mixin icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 1.7rem;
  color: $colorBlack;
  content: '';
}
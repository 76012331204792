
.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
    @include defaultTrans;
    box-shadow: 0px 0px 0px 1px $colorGrey;
    &:hover {
      @include defaultTrans;
      box-shadow: 0px 0px 0px 2px $colorGrey;
    }
  }
  .action {
    @include fontDefault;
    cursor: pointer;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-right: 2rem;
    @include defaultTrans;
    @include fontSmall;
    border: none;
    line-height: 1;
    text-transform: uppercase;
    @include defaultFastTrans;
    &.primary, &.tocart.primary, &.allow {
      color: $colorWhite;
      background-color: $colorPrimary;
      box-shadow: 0px 0px 0px 1px $colorPrimary;
      &:hover {
        @include defaultTrans;
        background-color: $colorSecondary;
        box-shadow: 0px 0px 0px 2px $colorSecondary;
      }
      &.tocart.disabled {
        border-color: $colorPrimary;
        background-color: $colorPrimary;
      }

    }
    &.secondary, &.action-edit-address,  &.view, &.action.edit-address-link, &.tocategory.secondary, &.totag.secondary, &.action-select-shipping-item, &.back, &.tocart, &.reload, &.clear, &.update, &.action-hide-popup, &.action-show-popup, &.action-update, &.action-cancel, &.share {
      color: $colorGrey;
      background-color: $colorWhite;
      box-shadow: 0px 0px 0px 1px $colorGrey;
      &:hover {
        @include defaultTrans;
        box-shadow: 0px 0px 0px 2px $colorGrey;
      }
    }

    @media only screen and (max-width: 768px) {
      font-size: 1.4rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      line-height: 1.4;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      text-align: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.block.block-dashboard-orders .block-title.order a.action.view {
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  margin-right: 0;
}
.secondary       .action.back {
  margin-right: 0 !important;
  border: 0 !important;
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}
.top-container .overlay .action{
  display: inline-block;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: 1.4rem 2rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;
  @include defaultTrans;
  &:hover {
    @include defaultTrans;
    background-color: $colorWhite;
  }
}
.page-main  .actions-toolbar {
  display: flex;
  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    .primary {
      margin-bottom: 0.75rem;
    }
  }

}
.page-main .action.delete, .page-main .action-delete, .page-header .action.delete {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    height: 2.5rem;
    width: 2.5rem;
    background-image: url($iconDir + "close.png");
    content: '';
    display: block;
  }

}
.page-main .action.edit, .page-main .action-edit {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    @include fontIcon;
    @include icon;
    content: $iconEdit;
    width: 1.7rem;
    height: 1.7rem;
  }
}
.page-main .action.change-password, .page-main .action-change-password {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  color: white;
  text-align: right;
  width: 1.7rem;
  font-size: 0;
  margin-left: 0.5rem;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    @include fontIcon;
    @include icon;
    content: $iconKey;
    width: 1.7rem;
    height: 1.7rem;
  }
}

.widget.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
input.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
ul.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }
}

body.page-layout-2columns-left .products.wrapper.grid.products-grid {
  .products.header {
    padding-top: 5rem;
    @include flexBetween;
    padding-bottom: 3.3rem;
    ul {
      @include flex;
      li {
        a {
          margin-left: 2rem;
          border-color: #C8C8C8;
          color: black;
          &:hover {
            box-shadow: 0 0 1px 1px #C8C8C8;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1280px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 1024px) {
    .products.header {
      margin-right: 0;
      margin-top: 4rem;
      text-align: center;
      justify-content: center;
    }
    ul.categories.list {
      display: none;
    }
  }
}
.page-products {
  .message {
    max-width: 134rem;
    margin: 0 auto;
  }
}


/*Product List*/
.products {
  flex-wrap: wrap;
  display: flex;
  .products.list {
    justify-content: flex-start;
    margin-left: -1%;
    margin-right: -1%;
    width: 102%;
    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: inherit;
    }
    .product.item {
      width: 23%;
      margin-top: 0.4rem;
      margin-left: 1%;
      margin-right: 1%;
      padding: 1.2rem;
      margin-bottom: 0.4rem;
      box-shadow: 0 0 4px 0 rgba(26,23,27,.2);
      box-sizing: border-box;
      position: relative;
      .product.image {
        position: relative;
        a, span {
          display: block;
          padding-bottom: 0px !important;
          width: inherit !important;
        }
        img {
          max-height: 46.5rem;
          max-width: 100%;
          height: auto;
        }
        .product.price {
          display: none;
          padding: 3rem;
          position: absolute;
          top: 0;
          z-index: -1;
          bottom: 0;
          left: 0;
          right: 0;
          span.price {
            font-size: 2.4rem;
            line-height: 2.8rem;
            text-transform: uppercase;
            text-shadow: 0 0 0.8rem $colorLightGrey;
            letter-spacing: 0.1rem;
            padding: 0 1.2rem;
            color: $colorWhite;
            margin: 0.3rem 0;
          }
          span.tax-details {
            display: none;
          }
        }
      }
      .product.details {
        padding: 0 3rem;
        margin-bottom: 14rem;
        .product-item-link {
          margin-top: 4rem;
          padding-bottom: 2rem;
          @include fontLarge;
          letter-spacing: 0.01rem;
          font-weight: bold;
          line-height: 3rem;
          text-transform: uppercase;
          display: block;
        }
        .short_description {
          margin: 0rem 0px 4rem 0px;
          //padding: 1rem 2rem;
          line-height: 3rem;
          text-transform: uppercase;
          padding-bottom: 0;
        }
        .product.actions {
          margin-top: auto;
          margin-bottom: 3rem;
          padding-bottom: 2rem;
          a {
            margin: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
      .product.inner {
        position: absolute;
        bottom: 0;
        width: calc(100% - 6rem);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
      }
      @media only screen and (max-width: 1024px) {
        width: 48%;
      }
      @media only screen and (max-width: 768px) {

      }
      @media only screen and (max-width: 480px) {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.checkout-cart-index {
  .page-title {
    text-align: center;
  }
  .cart-empty {
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: center;
    padding-bottom: 6rem;
  }
  h1 {
    max-width: $contentWidth;
    margin: 0 auto;
    text-align: left;
    line-height: 1;
  }
}
.message.info.empty {
  margin-bottom: 6rem;
  @media only screen and (max-width: 480px) {
    margin-bottom: 2.4rem;
  }
}
.checkout-index-index * {
  outline: none;
}
.checkout-index-index {


  .page-main {
    padding-top: 0;
    max-width: $contentWidth;
    margin: 0 auto;
    margin-top: 12.2rem;
  }

  .page-wrapper .columns .main .page-title-wrapper {
    padding-top: 0;
  }
  .step-title {
    margin-bottom: 2rem !important;
  }
}
.checkout-container {
  @include flexWrap;
  justify-content: space-between;
  align-items: flex-start;
  .note {
    @include fontSmaller;
  }
  .opc-progress-bar {
    padding-bottom: 3rem;
    padding-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    counter-reset: i;
    max-width: 95.2rem;
    li {
      margin: 0 0 1rem;
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 50%;
      max-width: 46.4rem;
      &:before {
        background: $colorGrey;
        top: 2.9rem;
        content: "";
        height: 0.05rem;
        left: 0;
        position: absolute;
        width: 100%;
      }
      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;
      }
      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }
      > span {
        display: block;
        padding-bottom: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        color: $colorBlack;
        text-align: center;
        line-height: 0;
        font-weight: bold;
        text-transform: uppercase;
        &:after {
          left: 50%;
          position: absolute;
          margin-left: -1.3rem;
          top: 2rem;
          content: counter(i);
          counter-increment: i;
          background: white;
          padding: 3px;
          border-radius: 50%;
          border: 0.5px solid $colorGrey;
          display: inline-block;
          height: 2.3rem;
          width: 2.3rem;
          padding-left: 5px;
          font-weight: bold;
          color: black;
          background-size: 70%;
          content: counter(i);
          counter-increment: i;
          font-size: 1.6rem;
          line-height: 1;
          @media only screen and (max-width: 480px) {
            line-height: 1.4 !important;
          }

        }
      }
      &._active {
        &:before {
          background: $colorGrey;
          height: 0.2rem;
        }
        > span {
          color: $colorBlack;
          line-height: 0;
          &:after {
            border: 2px solid $colorGrey;
            font-size: 1.6rem;
            line-height: 1;
            @media only screen and (max-width: 480px) {
              line-height: 1.4 !important;
            }
          }
        }
      }
      &._complete {
        cursor: pointer;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorBlack;
          &:after {

          }
        }
      }
    }
  }
  .authentication-wrapper {
    .action-auth-toggle {
      background: none;
      padding: 0;
      border: none;
      color: $colorPrimary;
      @include fontLarge;
      &:hover {
        opacity: 0.5;
      }
    }
    .authentication-dropdown {
      position: absolute;
      background: $colorWhite;
      border: 1px solid $colorGrey;
      margin-top: 0.5rem;
      max-width: 1500px;
      width: 100%;
      z-index: 101;
      box-shadow: 0 0.3rem 0.3rem $colorGrey;
      padding: 2.5rem 2rem;
      visibility: hidden;
      &._show {
        z-index: 100;
        transform: scale(1, 1);
        transition: transform linear 0.1s, visibility 0s linear 0s;
        visibility: visible;
      }
    }
  }
  .opc-estimated-wrapper {
    display: none;
  }
  .messages {
    grid-column: 1 / 3;
    grid-row: 3;
  }

  .opc-wrapper {
    width: 100%;
    max-width: 46.4rem;
    .choice {
      flex-wrap: wrap;
      .label {
        width: calc(100% - 30px);
      }
      .mage-error {
        bottom: 0;
        margin-left: 0 !important;
        margin-bottom: 1.5rem;
      }
    }
    label.label {
      border: none;
      line-height: 1;
      display: block;
      padding-bottom: 1rem;
    }
    ol#checkoutSteps {
      list-style: none;
      .shipping-address-item {
        margin-bottom: 1.5rem;
      }
      .field._required._warn {
        position: relative;
        .message.warning {
          position: absolute;
          background: #B0BAA5;
          color: #fff;
          z-index: 9;
          top: 6rem;
        }
      }
    }
    .new-address-popup {
      margin-bottom: 2.5rem;
    }
    .control._with-tooltip {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      .input-text {
        flex-basis: 94%;
      }
      .field-tooltip {
        cursor: pointer;
        &.toggle {
          #tooltip-label {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
          #tooltip {
            &:before {
              @include fontIcon;
              font-size: 2rem;
              color: $colorBlack;
              content: "#{$iconQuestion}";
            }
          }
        }
        &._active .field-tooltip-content {
          display: block;
        }
        .field-tooltip-content {
          line-height: 1;
          background: #707173;
          border: 0.1rem solid #707173;
          border-radius: 0.1rem;
          color: white;
          padding: 1.2rem;
          width: 27rem;
          display: none;
          margin-left: 4rem;
          position: absolute;
          text-transform: none;
          top: -0.9rem;
          word-wrap: break-word;
          z-index: 2;
          right: -29rem;
          &:before, &:after {
            border: 1rem solid transparent;
            height: 0;
            width: 0;
            border-right-color: #707173;
            left: -2.1rem;
            top: 1.2rem;
            content: "";
            display: block;
            position: absolute;
            z-index: 3;
          }
          &:before {
            border-right-color: #707173;
          }
          &:after {
            border-right-color: #707173;
            width: 0.1rem;
            z-index: 4;
          }
        }
      }
      .note {
        flex-basis: 100%;
        margin-top: 0.5rem;
        color: $colorBlack;

      }
    }
    .control {
      margin: 0rem 0;
      input {
        width: 100%;
      }
    }
    .step-title {
      @extend h3;
      text-transform: uppercase;
      border: 0;

      text-align: left;
    }
    .checkout-billing-address {
      margin-bottom: 2rem;
      .action-cancel {
        margin-left: 1.5rem;
      }
      .billing-address-details {
        br {
          display: block;
        }
      }
      .field-select-billing {
        margin-bottom: 2rem;
      }
      .billing-address-same-as-shipping-block {
        margin-bottom: 1rem;
        margin-top: 1rem;
        @include flex;
        line-height: 1;
        align-items: center;
        input {
          width: inherit;
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }
      fieldset.fieldset {
        padding-bottom: 0;
        .control {
          margin: 0.5rem 0 1rem;
        }
      }
    }
    fieldset.field.street.admin__control-fields.required{
      legend {
        display: none;
      }
    }
    form fieldset {
      padding-bottom: 0;
    }
    li#shipping {
      span.note {
        line-height: 2.4rem !important;
      }
      .amazon-button-container {
        margin-bottom: 2rem;
      }
      form.form-login {
      }
      .note {
        border: none;
        line-height: 4.3rem;
        display: block;
        margin-bottom: 2rem;
      }
    }
    li#opc-shipping_method{
      td.col.col-method {
        input.radio {
          margin-bottom: 0;
        }
      }
      #checkout-shipping-method-load {
        table.table-checkout-shipping-method {
          width: 100%;
          border-collapse: collapse;
          .col {
            cursor: pointer;
            padding: 0;
            border-bottom: 0.1rem solid $colorBlack;
            line-height: 1;
          }
          tr {
            &:last-child {
              .col {
                border-bottom: 0px;
              }
            }
          }
        }
      }
    }
    #payment {
      legend.legend {
        @extend h3;
        width: 100%;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        text-align: left;
        border-bottom: 0.1rem solid black;
      }
      .payment-method {
        margin-bottom: 0 !important;
        cursor: pointer;
        border-bottom: 0.1rem solid $colorBlack;
        padding: 1.5rem 0;
        .payment-method-content {
          display: none;
        }
        .messages {
          margin: 0;
        }
        &._active {
          .payment-method-title.field.choice {
            padding-left: 0;
          }
          .payment-method-content  {
            display: block;
            line-height: 1 !important;
            @include fontSmall;
            padding-top: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            .field.payment-image {
              margin-bottom: 1.5rem;
            }
            .mage-error {
              margin-left: 15px;
            }
            .checkout-agreements-block {
              padding-top: 1.2rem;
            }
          }
        }

        .payment-method-title {
          font-weight: bold;
          width: 100%;
          @include flex;
          align-items: center;
          label {
            cursor: pointer;
            padding-left: 0rem;
            padding-bottom: 1rem;
          }
          input {
            width: 1.5rem;
            margin-bottom: 0;
          }
        }
        label button {
          text-transform: none;
          background-color: transparent;
          color: black;
          font-weight: normal;
          padding: 0;
          vertical-align: inherit;
          border: none;
          margin-right: 0;
          text-align: left;
          &:hover {
            text-decoration: underline;
          }
        }
        .primary {
          width: 100%;
          margin-top: 1rem;
        }
        button.action.primary.checkout {
          width: 100%;
          margin-top: 10px;
        }
        .checkout-agreement.field.choice.required {
          padding-left: 0px;
          padding-top: 5px;
          @include flex;
          align-items: center;
          input {
            width: inherit;
            margin-right: 1rem;
            margin-left: 0;
          }
          label:after {
            top: -10px;
            right: -10px;
          }
        }
        .payment-method-title.field.choice label {
          cursor: pointer;
          padding-bottom: 0;
          padding-left: 0.5rem;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
      .payment-group{
        .step-title {
          margin-bottom: 0rem !important;
        }
      }
    }
    button.action.action-edit-address {
      margin-top: 1.5rem;
    }
  }
  #checkout-loader {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0rem;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba($colorGrey, 0.75);
    .loader {
      img {
        max-width: 4.4rem;
        position: fixed;
        top: 25%;
        left: 50%;
        z-index: 9999;
      }
    }
  }
  .opc-summary-wrapper {
    width: 100%;
    padding: 0;
    padding-top: 0;
    max-width: 32.2rem;
    margin-top: -9.5rem;
    @media only screen and (max-width: 1500px) {
      max-width: 32.2rem;
      margin-top: 0rem;
      margin-left: 3rem;
    }
    .modal-inner-wrap {
      header {
        display: none;
      }
      .opc-block-summary {
        table.data.table.table-totals {
          tbody td {
            padding: 0;
          }
        }
        span.title {
          margin-bottom: 2.6rem;
          padding-bottom: 0;
          display: block;
          text-align: left;
          text-transform: uppercase;
          line-height: 1;
          color: black;
          font-weight: bold;
        }
        table {
          width: 100%;
          margin-top: 1rem;
          caption.table-caption {
            display: none;
          }
          th {
            text-align: left;
          }
          td {
            text-align: right;
          }
          tr.grand.totals {
            padding-top: 0.5rem;
            color: black;
            margin-bottom: 1.5rem;
          }
        }
        .block.items-in-cart {
          margin-top: 2rem;
          cursor: pointer;
          .title {
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            position: relative;
            color: black;
            line-height: 2.2rem;
            &:after {
              color: $colorGrey;
              @include icon;
              width: 1.7rem;
              height: 1.7rem;
              transform: rotate(180deg);
              float: right;
              position: absolute;
              right: 0;
              bottom: 1rem;
              @include defaultTrans;
              @include fontIcon;
              @include icon;
              content: "\f077";

            }

          }
          &.active .title{
            &:after {
              transform: rotate(90deg);
              @include defaultTrans;
            }
          }
          ol.minicart-items {
            list-style: none;
            li.product-item {
              padding-bottom: 2rem;
              margin-bottom: 2rem;
              border-bottom: 0.1rem solid black;
              &:last-child {
                border: none;
              }
              span.product-image-container {
                width: 5rem !important;
                height: 5.6rem !important;
                margin-right: 1.5rem;
                display: block;
                span {
                  display: block;
                }
              }
              .product {
                display: flex;

                img {
                  width: 5rem;
                  height: 5.6rem;
                }
                .product-item-details {
                  width: calc(100% - 9.8rem);

                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  @include fontSmall;
                  line-height: 2rem;
                  .details-qty {
                    display: none;
                  }
                  .product.options {
                    span.toggle {
                      display: none;
                    }
                  }
                  .product-item-brand-block {
                    padding-bottom: 1rem;
                  }
                  .product-item-inner {
                    height: 100%;
                    color: black;
                    strong.product-item-name {
                      font-weight: bold;
                      padding-bottom: 0.4rem;
                      display: block;
                    }

                    span.cart-price {
                      font-weight: bold;
                    }
                    .product-item-name-block {

                    }
                  }
                }
              }
            }
          }
        }

      }
      .opc-block-shipping-information {
        .shipping-information-title {
          border-bottom: 1px solid rgba(0,0,0,0.4);
          margin-bottom: 2rem;
          padding-bottom: 1rem;
          @include flexBetween;
          button {
            background-color: transparent;
          }
        }
        .ship-to {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 0rem;
    padding-left: 0rem;
    .opc-wrapper {
      order: 3;
    }
    .opc-progress-bar, .opc-summary-wrapper{
      width: 100%;
      padding: 2.4rem;
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      margin: 0 auto;
    }
    .opc-wrapper {
      width: 100%;
      max-width: 100%;

      div#shipping-method-buttons-container .primary, div#checkout-payment-method-container .primary {
        margin: 0 auto !important;
        margin-bottom: 2.5rem !important;
      }

      .field.addresses, .new-address-popup {
        text-align: left;

        button {
          min-width: 25rem;
          @media only screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }

      .new-address-popup {
        text-align: center;
      }

      .step-title {
        margin-bottom: 1rem !important;
      }

      #payment .payment-method:last-child {
        padding-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: 480px) {
     .opc-progress-bar li > span {
      padding-bottom: 0;
      line-height: 140% !important;
    }
    .opc-wrapper .step-title {
      font-size: 1.8rem;
    }
    .opc-progress-bar li:before {
      top: 5.9rem;
    }
    .opc-progress-bar li > span:after {
      top: 5rem;
      padding-top: 0;
    }
    .actions-toolbar {

      .primary {
        min-width: 100%;
      }
    }
  }
  div#registration {
    .action {
      display: block;
      max-width: 20rem;
      margin-top: 1.5rem;
    }
  }
  li#opc-shipping_method {
    margin-top: 2.5rem;
  }
  div#checkout-shipping-method-load, div#checkout-payment-method-load {
    padding: 2rem;
    background-color: #F4F4F4;
    margin-bottom: 2.5rem;
  }
  div#shipping-method-buttons-container,  div#checkout-payment-method-container{

    button.primary {
      margin-bottom: 7.1rem;
      min-width: 23rem;
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
  div#checkout-shipping-method-load {
    td.col.col-method:first-child {
      width: 1.5rem;
    }
    td.col.col-method:last-child {
      padding-left: 1rem !important;
    }
  }
  @media only screen and (max-width: 1500px) {
    justify-content: flex-start;
  }
}


/*Modal*/


body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%;
  .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899;
  }
}

.modal {
  &-slide, &-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    button{
      cursor: pointer;
    }
    &._show{
      visibility: visible;
      @include flex;
      justify-content: center;
      align-items: flex-start;
      > div{
        display: none;
      }
      .modal{
        &-inner-wrap{
          display: flex;
          top: 0;
          opacity: 1;
        }
      }
    }
    .modal{
      &-inner{
        &-wrap{
          background-color: #fff;
          box-shadow: 0 0 12px 2px rgba(0, 0, 0, .35);
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &-slide{
    left: 44px;
    z-index: 900;
    &._inner-scroll{
      .modal{
        &-inner-wrap {

          overflow-y: visible;

        }
        &-header, &-footer{
          -webkit-flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-grow: 0;
          flex-shrink: 0;
        }
        &-content {

          font-size: 1.4rem;
          line-height: 1.4;
          h3 {
            text-align: left;
            padding-top: 2rem;
          }
        }

      }
    }
    .modal{
      &-inner-wrap{
        -webkit-transition-property: -webkit-transform, visibility;
        height: 100%;
        overflow-y: auto;
        position: static;
        transition-duration: .3s;
        transition-property: transform, visibility;
        transition-timing-function: ease-in-out;
        width: auto;
      }
      &-header, &-content, &-footer{

      }
      &-header{
        margin-bottom: 4rem;
      }
    }
    .action-close {
      padding: 2.1rem 3rem;
    }
    .page-main-actions {
      margin-bottom: -12.9rem;
      margin-top: 2.1rem;
    }
  }

  &-popup{
    left: 0;
    overflow-y: auto;
    z-index: 900;


    &._inner-scroll{
      .modal{
        &-inner-wrap {
          margin: 4rem auto;
        }
        &-content {
          height: auto;
          padding-bottom: 2rem;
        }
      }
    }
    .modal{
      &-content{
        display: flex;
        width: 100%;
        overflow: auto;
        .block{
          &-authentication{
            display: flex;
            align-items: flex-start;

          }
          &-new-customer{
            width: 27rem;
            order: 1;
            padding-left: 2rem;
            a.action{
              margin-right: 0;
            }
          }
          &-customer-login{
            width: calc(100% - 27rem);
            padding-right: 2rem;
            border-right: 1px solid $colorBorder;
          }
          &-title{
            @include fontHeadline;
            margin-bottom: 1rem;
          }
          &-content{

            ul{
              list-style: disc;
              padding-left: 1.5rem;
            }
            .actions{
              &-toolbar{
                margin-top: 1rem;
                display: flex;
                a{
                  display: inline-block;
                }
                .primary, .secondary{
                  border: 0;
                  box-shadow: none;
                }
                .action{
                  border: 1px solid $colorBorder;
                  > *{

                  }

                }

              }
            }
          }
        }
        >div{
          width: 100%;
        }
        form{

          padding: 2rem 0 4rem;
        }
        .product{
          &-info{
            &-price{
              padding: 2rem 0;
              border-top: .1rem solid $colorBorder;
              border-bottom: .1rem solid $colorBorder;
              font-size: 2.2rem;
              color: $colorPrimary;
              margin-top: 3.1rem;
              .price{
                &:after{
                  content: "";
                }
              }
            }
            &-media{
              width: 40%;
            }
            &-main{
              width: 100%;
              padding-left: 3rem;
              .product{
                &-attribute{
                  font-size: 1.6rem;
                  @include flexVertCenter;
                  margin-bottom: 1rem;
                  &.size{
                    .value{
                      min-width: 3.5rem;
                      border: 0.5px solid $colorBorder;
                      height: 3.5rem;
                      border-radius: 0;
                      @include flexCenter;
                      margin-right: 1.2rem;
                      padding: 5px 5px;
                    }
                  }
                  .label{
                    width: 30%;
                  }
                  .value{
                    margin-right: 1.2rem;

                  }
                }
                h2{
                  padding: 0;
                  @include fontHeadline;
                  font-size: 2.5rem;
                  margin-bottom: 2.6rem;
                  line-height: 3rem;
                  color: $colorBlack;
                }
                strong{
                  font-weight: 500;
                }
              }
            }
          }

        }

      }
      &-inner-wrap {
        box-sizing: border-box;
        @include flexColumn;
        height: auto;
        width: 90%;
        max-width: 100rem;
        max-height: 90vh;
        margin: 4rem auto;
        position: relative;
        transition-duration: .2s;
        transition-property: transform, visibility;
        transition-timing-function: ease;
        padding: 4rem 4.5rem;
        border-top: .9rem solid $colorPrimary;
        border-bottom: .9rem solid $colorPrimary;
        transition: all ease .2s;
        top: -100%;
        opacity: 0;
      }
      &-header, &-footer{
        -webkit-flex-grow: 0;
        -webkit-flex-shrink: 0;
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
      }
      &-header{
        margin-bottom: 4rem;
        min-height: 2rem;
      }
      &-footer {
        @include flex;
        flex-wrap: wrap;
        margin-top: 2rem;

        border:0;
        .action, button{
          width: 49%;
          margin: 0;
          text-align: center;
          border:0;
          font-size: 1.3rem;
          font-weight: 700;
          padding: 1.5rem;
          border:0;
          span{
            display: inline-block;
            width: 100%;
          }
          &.secondary, &-secondary{
            margin-left: 2%;
            text-align: center;
          }
          &-view-cart{
            background: $colorWhite;
            color: $colorBlack;
            border: 1px solid $colorBorder;
          }
          &-view-checkout{
            width: 100%;
            background: $colorPrimary;
            color: $colorWhite;
            margin-top: 1.4rem;
          }
        }
        &-actions {
          text-align: right;
        }
      }
      &-title {
        min-height: 1em;
        padding: .5rem 0;
        text-align: left;
        @include fontHeadline;
        font-size: 2.5rem;
        line-height: 3.5rem;
        position: relative;
        &:after{
          position: absolute;
          content: "";
          left: 0;
          top: 100%;
          height: 4px;
          width: 100%;
          max-width: 8.6rem;
          background: $colorPrimary;
        }
      }
    }
    .action-close {
      padding: 20px;
    }
  }
  &-costum, &-popup, &-slide{
    .modal{
      &-header, &-footer{
        position: relative;

      }
    }
    .action-close{
      -moz-box-sizing: content-box;
      background: none;
      background-image: none;
      border: 0;
      box-shadow: none;
      display: inline-block;
      font-weight: 400;
      line-height: inherit;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-decoration: none;
      text-shadow: none;
      top: 0;
      text-align: center;
      font-size: 1rem;

      &:focus, &:active{
        background: none;
        border: none;
      }
      &:hover{
        background: none;
        border: none;
      }
      &.disabled, &[disabled]{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }

      &:before{
        content: "\f00d";
        display: inline-block;
        text-align: center;
        @include fontIcon;
        font-weight: normal;
        font-size: 2rem;
        color: $colorPrimary;
        border-radius: 50px;
        border: 1px solid $colorPrimary;

        height: 2.3rem;
        width: 2.3rem;
        @include flexCenter;
        margin: 0 auto;

      }
      > span{
        color: $colorPrimary;
        width: 100%;
        display: inline-block;
        text-align: center;

      }
      &:active {
        &:before {
          color: inherit;
        }
      }
      &:hover{
        &:before{
          color: #000;
        }
      }
    }
  }
  &-costum{
    .action-close {
      margin: 25px;
    }
  }
  &s-overlay {
    background-color: rgba(51, 51, 51, .55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 899;
  }
} /* Modal */
.fieldset[disabled]{
  .modal{
    &-costum, &-popup, &-slide{
      .action-close{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}

.ie10 .modal-popup._inner-scroll, .ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
  .modal-inner-wrap {
    max-height: auto;
  }
}

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899;
}

/*Modal Ende*/

/*Loaden*/
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  p  {
    display: none;
  }
}

.load.indicator {
  background-color: rgba($colorGrey, 0.5);
  z-index: 9999;

  &:before {

    border-radius: 0.5rem;
    box-sizing: border-box;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  > span {
    display: none;
  }
}
html {
  position: relative ;
}
.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed !important;
  right: 0rem;
  top: 0;
  z-index: 9999;
  background: rgba($colorGrey, 0.75);
}

.loader {
  > img {
    max-width: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
}
.checkout-success .actions-toolbar {
  margin-top: 15px;
}
.checkout-success {
  max-width: $contentWidth;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
#registration {
  max-width: $contentWidth;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 4rem;
  a.action.primary {
    margin-top: 1.5rem;
    display: inline-block;
  }
}
form.form.form-login {
  margin-bottom: 2rem;
}
/*Ende*/



